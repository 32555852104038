<script setup lang="ts">
import {computed, ref} from "vue";
import {useTranslation} from "i18next-vue"

import {type ReimbursementFile, ReimbursementFileType, reimbursementFileUpload} from "@/js/vue-components/ospe/reimbursement-file";
import ProgressIndicator from "@/js/vue-components/progress-indicator.vue";
import Alert from "@/js/vue-components/alert.vue";
import {isNonEmptyString} from "@/js/js-utils";

const {t: $t} = useTranslation(undefined, { keyPrefix: 'reimbursement_files_certificates_control' })

const props = defineProps({
  files: {default: (): ReimbursementFile[] => [], type: Array as () => ReimbursementFile[]},
  uploadUrl: { default: null, type: String },
  fileType: { default: null, type: Number as () => ReimbursementFileType },
  title: { default: null, type: String },
})

const emit = defineEmits<{
  (e: 'file-uploaded', file: ReimbursementFile): void
  (e: 'delete-file', file: ReimbursementFile): void
  (e: 'restore-file', file: ReimbursementFile): void
}>()

const reimbursementFileField = ref<HTMLInputElement | null>(null)
const isUploading = ref(false)
const uploadError = ref<string | null>(null)
const uploadProgress = ref(0)
const uploadMessage = ref<string | null>(null)

const canUpload = computed(() => withUpload.value && !isUploading.value)
const noFiles = computed(() => props.files.length == 0)
const withUpload = computed(() => isNonEmptyString(props.uploadUrl))
const uploadErrorVisible = computed(() => isNonEmptyString(uploadError.value))

const uploadReimbursementFile = () => {
  const field = reimbursementFileField.value
  if (field && (field.files?.length || 0) > 0) {
    reimbursementFileUpload(
      field,
      props.uploadUrl,
      props.fileType,
      {
        startCallback() {
          isUploading.value = true
          uploadProgress.value = 0
          uploadMessage.value = null
          uploadError.value = null
        },
        progressCallback(progress: number, message: string) {
          uploadProgress.value = progress
          uploadMessage.value = message
        },
      }
    ).then((file) => {
      emit('file-uploaded', file)
    }).catch((error: string) => {
      uploadError.value = error
    }).then(() => {
      isUploading.value = false
    })
  }
}

const deleteFile = (file: ReimbursementFile) => {
  emit('delete-file', file)
}

const restoreFile = (file: ReimbursementFile) => {
  emit('restore-file', file)
}

</script>

<template>
  <div class="report-files">
    <div class="card files">
      <div class="card-body">
        <h5 class="card-title">
          {{ title }}
        </h5>

        <div
          v-if="noFiles"
          class="alert alert-warning"
        >
          {{ $t('files.empty') }}
        </div>
        <div
          v-else
          class="list-group list-group-flush mb-3"
        >
          <div
            v-for="(file, fileIdx) of files"
            :key="`report-file-${fileIdx}`"
            :class="['list-group-item', { 'list-group-item-danger': file.deleted }]"
          >
            <div class="form-row">
              <div class="col col-12 col-md form-group">
                <label>{{ $t('files.description') }}</label>
                <input
                  v-model="file.title"
                  :readonly="!file.editable"
                  class="form-control form-control-sm"
                  data-test-id="file-title"
                >
              </div>
              <div class="col form-group">
                <label>{{ $t('files.file_name') }}</label>
                <input
                  v-model="file.filename"
                  class="form-control form-control-sm"
                  data-test-id="file-name"
                  :readonly="true"
                  type="text"
                >
              </div>
              <div class="col col-auto form-group-button">
                <template v-if="file.editable">
                  <button
                    v-if="file.deleted"
                    class="btn btn-sm btn-info"
                    type="button"
                    @click.prevent="restoreFile(file)"
                  >
                    Restore
                  </button>
                  <button
                    v-else
                    class="btn btn-sm btn-danger"
                    type="button"
                    @click.prevent="deleteFile(file)"
                  >
                    Delete
                  </button>
                </template>
              </div>
            </div>
            <small
              v-if="file.deleted"
              class="text-danger"
            >
              File will be removed on submission
            </small>
          </div>
        </div>

        <label
          v-if="withUpload"
          :class="['btn btn-sm btn-success', { disabled: !canUpload }]"
        >
          <span class="glyphicons icon-left upload" />
          <span>{{ $t('files.add_new') }}</span>
          <input
            ref="reimbursementFileField"
            :disabled="!canUpload"
            style="display: none;"
            type="file"
            @change="uploadReimbursementFile"
          >
        </label>
        <progress-indicator
          v-if="isUploading"
          :message="uploadMessage"
          :progress="uploadProgress"
          class="mt-3"
        />
        <alert
          v-if="uploadErrorVisible"
          :dismissable="true"
          :message="uploadError"
          class="mt-3 mb-0"
          @dismiss="uploadError = ''"
        />
      </div>
    </div>
  </div>
</template>
