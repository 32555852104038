import $ from 'jquery'
import CurricularActivityRegistration from "../vue-components/curricular-activity-registration.vue"
import { tsInitVue } from "../js-utils"
import moment from "moment"

const MAX_TRANSPORT_REFUND_DAYS = 8

document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('body.ospe-activity-registration, body.ospe-activity-expert-registration, body.ospe-project-personal-registration, body.ospe-project-open-registration')) {
    const refundTypeRowEl = document.querySelector('div[ref="refund-type-row"]')
    const participantStatusEl = document.querySelector('select[ref="status-id"]')
    const transportRefundEl = document.querySelector('select[ref="transport-refund-type"]')
    const perDiemRefundEl = document.querySelector('select[ref="per-diem-refund-type"]')
    // const perDiemRefundLabelEl = document.querySelector(perDiemRefundEl).siblings('label')
    const participantBankDetailsEl = document.querySelector('fieldset[ref="participant-bank-details"]')
    const institutionBankDetailsEl = document.querySelector('fieldset[ref="institution-bank-details"]')
    const refundInfoRows = document.querySelectorAll('div[ref="refund-info-row"]')

    const startDateEl = document.querySelector('[data-date-ref="reg-start-date"] input[ref="date"]')
    const endDateEl = document.querySelector('[data-date-ref="reg-end-date"] input[ref="date"]')
    const transportRefundWrapEl = document.querySelector('div[data-reg-field="transport-refund-type"]')

    if (
      refundTypeRowEl instanceof HTMLElement &&
      participantBankDetailsEl instanceof HTMLElement &&
      institutionBankDetailsEl instanceof HTMLElement
    ) {
      //
      // Dynamic transport refund type handling
      //
      const transportRefundByParticipant = refundTypeRowEl.dataset.transportRefundByParticipant != "false"
      if (transportRefundByParticipant &&
        startDateEl instanceof HTMLInputElement &&
        endDateEl instanceof HTMLInputElement &&
        transportRefundWrapEl instanceof HTMLElement) {
        const toggleTransportRefund = function(): void {
          let transportVisible = true

          const dStart = moment($(startDateEl).datepicker('getDate'))
          const dEnd = moment($(endDateEl).datepicker('getDate'))
          if (dStart.isValid() && dEnd.isValid()) {
            const diff = dEnd.diff(dStart, 'days') + 1
            if (diff < 1 || diff > MAX_TRANSPORT_REFUND_DAYS) {
              transportVisible = false
            }
          }

          if (transportVisible) {
            transportRefundWrapEl.classList.remove('d-none')
          } else {
            transportRefundWrapEl.classList.add('d-none')
          }
        }

        toggleTransportRefund() // initial page load handling

        $(startDateEl).on('pick.datepicker', function () {
          toggleTransportRefund()
        })
        startDateEl.addEventListener('change', function() {
          toggleTransportRefund()
        })
        $(endDateEl).on('pick.datepicker', function () {
          toggleTransportRefund()
        })
        endDateEl.addEventListener('change', function() {
          toggleTransportRefund()
        })
      }

      const participantBankType = parseInt(participantBankDetailsEl.dataset.refundType || '-1')
      const institutionBankType = parseInt(institutionBankDetailsEl.dataset.refundType || '-1')

      const enableBankDetails = function(): void {
        let bankDetailsHidden = false
        if (participantStatusEl instanceof HTMLSelectElement) {
          const statusId = parseInt(participantStatusEl.value)
          if (statusId === 3) {
            bankDetailsHidden = true

            // hide all bank details fields
            refundTypeRowEl.classList.add('d-none')
            participantBankDetailsEl.classList.add('d-none')
            institutionBankDetailsEl.classList.add('d-none')
            participantBankDetailsEl.setAttribute('disabled', '')
            institutionBankDetailsEl.setAttribute('disabled', '')

            refundInfoRows.forEach((el) => {
              if (el instanceof HTMLElement) {
                el.classList.add('d-none')
              }
            })
          } else {
            refundTypeRowEl.classList.remove('d-none')
          }
        }

        if (bankDetailsHidden) {
          return
        }

        let v1 = undefined
        if (transportRefundEl instanceof HTMLSelectElement) {
          v1 = parseInt(transportRefundEl.value)
        }

        let v2 = undefined
        if (perDiemRefundEl instanceof HTMLSelectElement) {
          v2 = parseInt(perDiemRefundEl.value)
        }

        if (v1 === participantBankType || v2 === participantBankType) {
          participantBankDetailsEl.classList.remove('d-none')
          if (refundInfoRows.length > 0) {
            refundInfoRows[0].classList.remove('d-none')
          }
          participantBankDetailsEl.removeAttribute('disabled')
        } else {
          participantBankDetailsEl.classList.add('d-none')
          if (refundInfoRows.length > 0) {
            refundInfoRows[0].classList.add('d-none')
          }
          participantBankDetailsEl.setAttribute('disabled', '')
        }

        if (v1 === institutionBankType || v2 === institutionBankType) {
          institutionBankDetailsEl.classList.remove('d-none')
          if (refundInfoRows.length > 1) {
            refundInfoRows[1].classList.remove('d-none')
          }
          institutionBankDetailsEl.removeAttribute('disabled')
        } else {
          institutionBankDetailsEl.classList.add('d-none')
          if (refundInfoRows.length > 1) {
            refundInfoRows[1].classList.add('d-none')
          }
          institutionBankDetailsEl.setAttribute('disabled', '')
        }
      }

      if (participantStatusEl instanceof HTMLElement) {
        participantStatusEl.addEventListener('change', function () {
          enableBankDetails()
        })
      }

      if (transportRefundEl instanceof HTMLSelectElement) {
        transportRefundEl.addEventListener('change', function () {
          enableBankDetails()
        })
      }

      if (perDiemRefundEl instanceof HTMLSelectElement) {
        perDiemRefundEl.addEventListener('change', function () {
          enableBankDetails()
        })
      }

      const activitiesEl = document.querySelector('div[data-is="CurricularActivityRegistration"]')
      if (activitiesEl instanceof HTMLElement) {
        const valueFieldRef = activitiesEl.dataset.valueFieldRef
        window.ospeRegistrationActivities = tsInitVue(CurricularActivityRegistration, activitiesEl, {
          valueFieldRef
        })
      }


      // set initial state for bank details
      enableBankDetails()
    }
  }
})
