import { tsInitVue } from '../js-utils'
import ReportSubmissionFilesControl from '../vue-components/ospe/report-submission-files-control.vue'

document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('body.ospe-report-submission')) {
    const filesControlEls = document.querySelectorAll('div[data-is="ReportFilesControl"]')
    if (filesControlEls.length > 0) {
      window.reportFilesControls = []
      for (let i = 0; i < filesControlEls.length; i++) {
        const el = filesControlEls[i]
        if (el instanceof HTMLElement) {
          const vueControl = tsInitVue(ReportSubmissionFilesControl, el, {
            uploadUrl: el.dataset.uploadUrl,
            filesFieldRef: el.dataset.filesFieldRef,
            title: el.dataset.title,
          })
          window.reportFilesControls.push(vueControl)
        }
      }
    }
  }
})
