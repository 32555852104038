import {GridFilter} from "@/js/vue-components/types";
import {isNonEmptyString} from "@/js/js-utils";

export function createFilter(items: string[]): GridFilter {
  const sortedItems = [...new Set(items)].filter( v => isNonEmptyString(v) )
  sortedItems.sort()

  const listItems = sortedItems.map( (c) => ({ value: c, title: c, type: "item" }) )
  const filterItems = [
    { value: "", title: "All", type: "item" },
    { type: "separator" }
  ].concat(listItems)

  return new GridFilter({
    type: "list",
    multiple: true,
    items: filterItems,
    selectedValue: ['']
  })
}

export function filterItems(filter: GridFilter): string[] {
  return filter.selectedValues.filter( (v) => isNonEmptyString(v) )
}
