<template>
  <div>
    <h4>{{ $t('short_term_application.training_preferences') }}</h4>
    <div>
      <div class="custom-control custom-checkbox">
        <input
          id="applicationGuaranteeConsent"
          v-model="applicationData.applicationGuaranteeConsent"
          type="checkbox"
          class="custom-control-input"
        >
        <label
          class="custom-control-label"
          for="applicationGuaranteeConsent"
        >
          {{ $t('short_term_application.training_preferences_consent') }}
        </label>
      </div>
    </div>

    <hr class="short">

    <div class="card available-exchanges">
      <div class="card-header">
        <span>{{ $t('short_term_application.available_places.title') }}</span>
        <button
          type="button"
          class="btn btn-sm btn-success"
          data-toggle="modal"
          data-target="#availableExchangesModal"
        >
          {{ $t('short_term_application.available_places.show') }}
        </button>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-6">
        <label class="required">{{ $t('short_term_application.nationality') }}</label>
        <select
          v-model="applicationData.nationalityId"
          class="custom-select"
          :disabled="!canChangeDetails"
        >
          <option />
          <option
            v-for="nationality of nationalities"
            :key="nationality.id"
            :value="nationality.id"
          >
            {{ nationality.name }}
          </option>
        </select>
      </div>
      <div
        v-if="lands.length > 0"
        class="form-group col-sm-6"
      >
        <label class="required">Land</label>
        <select
          v-model="applicationData.germanyLandId"
          class="custom-select"
        >
          <option />
          <option
            v-for="land of lands"
            :key="land.id"
            :value="land.id"
          >
            {{ land.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-6">
        <label class="required">{{ $t('short_term_application.ncp') }}</label>
        <select
          v-model="applicationData.ncpId"
          class="custom-select"
        >
          <option />
          <option
            v-for="ncp of ncps"
            :key="ncp.id"
            :value="ncp.id"
          >
            {{ ncp.name }}
          </option>
        </select>
      </div>
      <div
        v-if="requireIdNumber"
        class="form-group col-sm-6"
      >
        <label class="required">ID number</label>
        <input
          v-model="applicationData.idNumber"
          type="text"
          class="form-control"
        >
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-6">
        <label
          for="function-id"
          class="required"
        >
          {{ $t('short_term_application.function') }}
        </label>
        <select
          id="function-id"
          v-model="applicationData.functionId"
          class="custom-select"
          :disabled="!canChangeDetails"
        >
          <option :value="null" />
          <option
            v-for="aFunction of availableFunctions"
            :key="aFunction.id"
            :value="aFunction.id"
          >
            {{ aFunction.name }}
          </option>
        </select>
      </div>
      <div
        v-if="selectCourtStaffworkplaceVisible"
        class="form-group col-sm-6"
      >
        <label
          for="court-staff-workplace-id"
          class="required"
        >
          {{ $t('short_term_application.court_staff_workplace') }}
        </label>
        <select
          id="court-staff-workplace-id"
          v-model="applicationData.courtStaffWorkplaceId"
          class="custom-select"
          :disabled="!canChangeDetails"
        >
          <option :value="null" />
          <option
            v-for="workplace of availableCourtStaffWorkplaces"
            :key="workplace.id"
            :value="workplace.id"
          >
            {{ workplace.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-6">
        <label
          for="activity-category-id"
          class="required"
        >
          {{ $t('short_term_application.activity_category') }}
        </label>
        <select
          id="activity-category-id"
          v-model="applicationData.activityCategoryId"
          class="custom-select"
          :disabled="selectCategoryDisabled"
        >
          <option :value="null" />
          <option
            v-for="activityCategory of availableActivityCategories"
            :key="activityCategory.id"
            :value="activityCategory.id"
          >
            {{ activityCategory.name }}
          </option>
        </select>
      </div>

      <div
        v-if="selectSpecialisationVisible"
        class="form-group col-sm-6"
      >
        <label
          for="specialisation-id"
          class="required"
        >
          {{ $t('short_term_application.specialisation') }}
        </label>
        <select
          id="specialisation-id"
          v-model="applicationData.specialisationId"
          class="custom-select"
          :disabled="!canChangeDetails"
        >
          <option :value="null" />
          <option
            v-for="specialisation of availableSpecialisations"
            :key="specialisation.id"
            :value="specialisation.id"
          >
            {{ specialisation.name }}
          </option>
        </select>
      </div>
    </div>

    <template v-if="showInstructions">
      <hr class="short">
      <h4>{{ $t('project_based_application.instructions') }}</h4>
      <div
        v-if="instructionsHtml"
        v-html="instructionsHtml"
      />

      <div
        v-for="file of instructionsFiles"
        :key="file.url"
      >
        <a
          :href="file.url"
          target="_blank"
        >
          {{ file.fileName }}
        </a>
      </div>
    </template>

    <hr class="short">

    <div class="choice-cards">
      <short-term-exchange-choice-card
        :exchange="applicationData.firstChoice"
        :allow-delete="true"
        :title="$t('short_term_application.first_choice')"
        @show-select-exchange="showSelectExchangeModal(0)"
        @delete-exchange="showDeleteExchangePrompt(0)"
      />
      <short-term-exchange-choice-card
        v-if="secondChoiceCardVisible"
        :exchange="applicationData.secondChoice"
        :allow-delete="true"
        :title="$t('short_term_application.second_choice')"
        @show-select-exchange="showSelectExchangeModal(1)"
        @delete-exchange="showDeleteExchangePrompt(1)"
      />
      <short-term-exchange-choice-card
        v-if="thirdChoiceCardVisible"
        :exchange="applicationData.thirdChoice"
        :allow-delete="true"
        :title="$t('short_term_application.third_choice')"
        @show-select-exchange="showSelectExchangeModal(2)"
        @delete-exchange="showDeleteExchangePrompt(2)"
      />
    </div>
    <div
      v-if="addSecondChoiceButtonVisible || addThirdChoiceButtonVisible"
      class="mt-4"
    >
      <button
        v-if="addSecondChoiceButtonVisible"
        type="button"
        class="btn btn-success btn-sm"
        @click.prevent="showSelectExchangeModal(1)"
      >
        {{ $t('short_term_application.add_second_choice') }}
      </button>
      <button
        v-if="addThirdChoiceButtonVisible"
        type="button"
        class="btn btn-success btn-sm"
        @click.prevent="showSelectExchangeModal(2)"
      >
        {{ $t('short_term_application.add_third_choice') }}
      </button>
    </div>
    <div class="form-row mt-4">
      <div class="form-group col-12">
        <label class="required">{{ $t('application_form.other_country_allocation') }}</label>
        <div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              :id="`other-country-allocation-yes`"
              v-model="applicationData.allocateToOtherCountry"
              :value="true"
              type="radio"
              class="custom-control-input"
            >
            <label
              class="custom-control-label"
              :for="`other-country-allocation-yes`"
            >
              {{ $t('option.yes') }}
            </label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              :id="`other-country-allocation-no`"
              v-model="applicationData.allocateToOtherCountry"
              :value="false"
              type="radio"
              class="custom-control-input"
            >
            <label
              class="custom-control-label"
              :for="`other-country-allocation-no`"
            >
              {{ $t('option.no') }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <teleport
      to="#vue-portal-target"
      append
    >
      <div
        id="availableExchangesModal"
        class="modal fade available-exchanges-modal"
        tabindex="-1"
        role="dialog"
      >
        <div
          class="modal-dialog modal-xl"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ $t('short_term_application.available_exchanges.title') }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>{{ $t('short_term_application.available_exchanges.column.country') }}</th>
                    <th>{{ $t('short_term_application.available_exchanges.column.category') }}</th>
                    <th>{{ $t('short_term_application.available_exchanges.column.type') }}</th>
                    <th>{{ $t('short_term_application.available_exchanges.column.languages') }}</th>
                    <th>{{ $t('short_term_application.available_exchanges.column.functions') }}</th>
                    <th>{{ $t('short_term_application.available_exchanges.column.specialisation') }}</th>
                    <th>{{ $t('short_term_application.available_exchanges.column.available_places') }}</th>
                    <th>{{ $t('short_term_application.available_exchanges.column.length') }}</th>
                    <th>{{ $t('short_term_application.available_exchanges.column.additional_information') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="filter no-hover">
                    <td>
                      <grid-filter-list
                        :value="countryFilter"
                        :show-dropdown-caret="false"
                        @input="onFilterChanged(countryFilter, $event)"
                      >
                        {{ filterTitle(countryFilter) }}
                      </grid-filter-list>
                    </td>
                    <td>
                      <grid-filter-list
                        :value="categoryFilter"
                        :show-dropdown-caret="false"
                        @input="onFilterChanged(categoryFilter, $event)"
                      >
                        {{ filterTitle(categoryFilter) }}
                      </grid-filter-list>
                    </td>
                    <td>
                      <grid-filter-list
                        :value="typeFilter"
                        :show-dropdown-caret="false"
                        @input="onFilterChanged(typeFilter, $event)"
                      >
                        {{ filterTitle(typeFilter) }}
                      </grid-filter-list>
                    </td>
                    <td>
                      <grid-filter-list
                        :value="languageFilter"
                        :show-dropdown-caret="false"
                        @input="onFilterChanged(languageFilter, $event)"
                      >
                        {{ filterTitle(languageFilter) }}
                      </grid-filter-list>
                    </td>
                    <td>
                      <grid-filter-list
                        :value="functionFilter"
                        :show-dropdown-caret="false"
                        @input="onFilterChanged(functionFilter, $event)"
                      >
                        {{ filterTitle(functionFilter) }}
                      </grid-filter-list>
                    </td>
                    <td>
                      <grid-filter-list
                        :value="specialisationFilter"
                        :show-dropdown-caret="false"
                        @input="onFilterChanged(specialisationFilter, $event)"
                      >
                        {{ filterTitle(specialisationFilter) }}
                      </grid-filter-list>
                    </td>
                    <td></td>
                    <td>
                      <grid-filter-list
                        :value="durationFilter"
                        :show-dropdown-caret="false"
                        @input="onFilterChanged(durationFilter, $event)"
                      >
                        {{ filterTitle(durationFilter) }}
                      </grid-filter-list>
                    </td>
                    <td></td>
                  </tr>
                  <tr
                    v-for="exchange of filteredExchanges"
                    :key="exchange.id"
                  >
                    <td>{{ exchange.countryName }}</td>
                    <td>{{ exchange.category }}</td>
                    <td>{{ exchange.exchangeType }}</td>
                    <td>{{ exchange.languages }}</td>
                    <td>{{ exchange.functions }}</td>
                    <td>{{ exchange.specialisation }}</td>
                    <td>{{ exchange.availablePlaces }}</td>
                    <td>{{ exchange.duration }}</td>
                    <td>{{ exchange.additionalInformation }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {{ $t('modal.button.close') }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <remote-modal
        ref="selectExchangeModal"
        :modal-title="$t('application_form.select_exchange.title')"
        modal-class="modal-tabular"
        modal-dialog-class="modal-xl"
        :loading-message="$t('application_form.select_exchange.loading')"
        :loading-indicator-in-footer="false"
        :hide-content-when-loading="true"
        @details-loaded="onSelectExchangesLoaded"
      >
        <template
          v-if="selectExchangesListLoaded"
          v-slot:modal-content
        >
          <div
            v-if="selectExchangesList.length > 0"
            class="table-wrapper"
          >
            <div class="table-responsive">
              <table class="table table-hover table-sm">
                <thead>
                  <tr>
                    <th>{{ $t('application_form.choice.country') }}</th>
                    <th>{{ $t('application_form.choice.type') }}</th>
                    <th>{{ $t('application_form.choice.language') }}</th>
                    <th>{{ $t('application_form.choice.public') }}</th>
                    <th v-if="requireSpecialisation">{{ $t('application_form.choice.specialisation') }}</th>
                    <th>{{ $t('application_form.choice.available_places') }}</th>
                    <th>{{ $t('application_form.choice.duration') }}</th>
                    <th>{{ $t('application_form.choice.additional_information') }}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr class="filter no-hover">
                    <td>
                      <grid-filter-list
                        :value="selectCountryFilter"
                        :show-dropdown-caret="false"
                        @input="onFilterChanged(selectCountryFilter, $event)"
                      >
                        {{ filterTitle(selectCountryFilter) }}
                      </grid-filter-list>
                    </td>
                    <td>
                      <grid-filter-list
                        :value="selectTypeFilter"
                        :show-dropdown-caret="false"
                        @input="onFilterChanged(selectTypeFilter, $event)"
                      >
                        {{ filterTitle(selectTypeFilter) }}
                      </grid-filter-list>
                    </td>
                    <td>
                      <grid-filter-list
                        :value="selectLanguageFilter"
                        :show-dropdown-caret="false"
                        @input="onFilterChanged(selectLanguageFilter, $event)"
                      >
                        {{ filterTitle(selectLanguageFilter) }}
                      </grid-filter-list>
                    </td>
                    <td>
                      <grid-filter-list
                        :value="selectFunctionFilter"
                        :show-dropdown-caret="false"
                        @input="onFilterChanged(selectFunctionFilter, $event)"
                      >
                        {{ filterTitle(selectFunctionFilter) }}
                      </grid-filter-list>
                    </td>
                    <td v-if="requireSpecialisation">
                      <grid-filter-list
                        :value="selectSpecialisationFilter"
                        :show-dropdown-caret="false"
                        @input="onFilterChanged(selectSpecialisationFilter, $event)"
                      >
                        {{ filterTitle(selectSpecialisationFilter) }}
                      </grid-filter-list>
                    </td>
                    <td></td>
                    <td>
                      <grid-filter-list
                        :value="selectDurationFilter"
                        :show-dropdown-caret="false"
                        @input="onFilterChanged(selectDurationFilter, $event)"
                      >
                        {{ filterTitle(selectDurationFilter) }}
                      </grid-filter-list>
                    </td>
                    <td></td>
                  </tr>
                  <tr
                    v-for="exchange of filteredSelectExchangesList"
                    :key="exchange.id"
                  >
                    <td>{{ exchange.countryName }}</td>
                    <td>{{ exchange.exchangeType }}</td>
                    <td>{{ exchange.languages }}</td>
                    <td>{{ exchange.functions }}</td>
                    <td v-if="requireSpecialisation">{{ exchange.specialisation }}</td>
                    <td>{{ exchange.availablePlaces }}</td>
                    <td>{{ exchange.duration }}</td>
                    <td>{{ exchange.additionalInformation }}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-sm btn-info"
                        @click.prevent="selectExchange(exchange, exchangeSelectionIdx)"
                      >
                        {{ $t('application_form.select_exchange.button.select') }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            v-else
            class="alert alert-warning"
          >
            {{ $t('application_form.select_exchange.no_exchanges_found') }}
          </div>
        </template>
        <template v-slot:modal-footer>
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            data-dismiss="modal"
          >
            {{ $t('modal.button.close') }}
          </button>
        </template>
      </remote-modal>
      <modal-alert
        ref="modalAlert"
        :close-button-title="$t('modal.button.close')"
      />
      <modal-confirmation-rails
        ref="removeChoiceConfirmationModal"
        :modal-title="$t('short_term_application.remove_choice_confirmation.title')"
        @confirmed="onRemoveChoiceConfirmed"
      />
    </teleport>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Jsona from 'jsona'

import { isNonEmptyString } from "@/js/js-utils";
import { createFilter, filterItems } from "@/js/vue-components/filter-utils";
import RemoteModal from '../../vue-components/remote-modal.vue'
import ModalAlert from '../../vue-components/modal-alert.vue'
import ModalConfirmationRails from '../../vue-components/modal-confirmation-rails.vue'
import ShortTermExchangeChoiceCard from './short-term-exchange-choice-card.vue'
import GridFilterList from "@/js/vue-components/grid-filter-list.vue"

import type {
  ExchangePlace,
  ExchangeChoice,
  IdNamePair,
  OspeActivityType,
  OspeFunction,
  OspeNationality,
  OspeNcp,
  OspeSpecialisation,
  ShortTermApplication,
  OspeShortTermAvailableFunction,
  OspeShortTermAvailableCourtStaffWorkplace,
  OspeShortTermAvailableCategory,
  OspeShortTermAvailableSpecialisation, OspeProjectBasedActivityTemplateFile, OspeShortTermActivityCategoryInstructions
} from '../types'
import {GridFilter} from "@/js/vue-components/types";

export default defineComponent({
  name: 'ShortTermExchangeSelection',
  components: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    GridFilterList, ModalAlert, ModalConfirmationRails, RemoteModal, ShortTermExchangeChoiceCard
  },
  props: {
    availableExchangesUrl: { default: null, type: String },
  },
  data () {
    const applicationData: ShortTermApplication = {
      applicationGuaranteeConsent: false,
      activityCategoryId: null,
      functionId: null,
      courtStaffWorkplaceId: null,
      specialisationId: null,
      firstChoice: null,
      secondChoice: null,
      thirdChoice: null,
      nationalityId: null,
      germanyLandId: null,
      ncpId: null,
      idNumber: null,
      allocateToOtherCountry: null
    }

    const countryFilter = new GridFilter({type: "list"})
    const categoryFilter = new GridFilter({type: "list"})
    const typeFilter = new GridFilter({type: "list"})
    const languageFilter = new GridFilter({type: "list"})
    const functionFilter = new GridFilter({type: "list"})
    const specialisationFilter = new GridFilter({type: "list"})
    const durationFilter = new GridFilter({type: "list"})

    const selectCountryFilter = new GridFilter({type: "list"})
    const selectTypeFilter = new GridFilter({type: "list"})
    const selectLanguageFilter = new GridFilter({type: "list"})
    const selectFunctionFilter = new GridFilter({type: "list"})
    const selectSpecialisationFilter = new GridFilter({type: "list"})
    const selectDurationFilter = new GridFilter({type: "list"})

    return {
      activityTypes: [] as OspeActivityType[],
      selectedActivityType: null as OspeActivityType | null,
      availableExchanges: [] as ExchangePlace[],
      selectExchangesList: [] as ExchangeChoice[],
      selectExchangesListLoaded: false,
      exchangeSelectionIdx: 0,
      applicationData,
      applicationDataEl: null as HTMLInputElement | null,
      nationalities: [] as OspeNationality[],
      availableFunctions: [] as OspeShortTermAvailableFunction[],
      instructions: {} as { [id: string]: OspeShortTermActivityCategoryInstructions },
      countryFilter,
      categoryFilter,
      typeFilter,
      languageFilter,
      functionFilter,
      specialisationFilter,
      durationFilter,
      selectCountryFilter,
      selectTypeFilter,
      selectLanguageFilter,
      selectFunctionFilter,
      selectSpecialisationFilter,
      selectDurationFilter,
    }
  },
  computed: {
    selectedActivityCategory (): OspeShortTermAvailableCategory | null {
      let categories = this.availableActivityCategories
      if (this.requireCourtStaffWorkplace) {
        const currentWorkplace = this.selectedCourtStaffWorkplace
        if (!currentWorkplace) {
          return null
        }
        categories = categories.filter(v => v.courtStaffWorkplaceIds.includes(currentWorkplace.id))
      }
      return categories.find( v => v.id == this.applicationData.activityCategoryId) || null
    },

    selectCategoryDisabled (): boolean {
      if (!this.canChangeDetails) {
        return true
      }

      if (this.requireCourtStaffWorkplace) {
        return this.applicationData.courtStaffWorkplaceId == null
      }

      return this.selectedFunction == null
    },

    requireSpecialisation(): boolean {
      return this.availableSpecialisations.length > 0
    },

    hasSpecialisation(): boolean {
      return this.applicationData.specialisationId !== null
    },

    hasFunction(): boolean {
      return this.applicationData.functionId !== null
    },

    hasNationality(): boolean {
      return this.applicationData.nationalityId !== null
    },

    selectSpecialisationVisible (): boolean {
      return this.availableSpecialisations.length > 0
    },

    // TODO: remove method
    functions (): OspeFunction[] {
      if (this.selectedActivityType) {
        if (this.selectedActivityType.requiresSpecialisation) {
          // get functions from the selected specialisations
          if (Array.isArray(this.selectedActivityType.specialisations)) {
            const specialisation = this.selectedActivityType.specialisations.find(spec => spec.id == this.applicationData.specialisationId)
            if (specialisation) {
              return specialisation.functions
            }
          }
        } else if (Array.isArray(this.selectedActivityType.functions)) {
          return this.selectedActivityType.functions
        }
      }
      return []
    },

    // TODO: remove
    specialisations(): OspeSpecialisation[] {
      if (this.selectedActivityType) {
        if (this.selectedActivityType.requiresSpecialisation) {
          if (Array.isArray(this.selectedActivityType.specialisations)) {
            return this.selectedActivityType.specialisations
          }
        }
      }
      return []
    },

    secondChoiceCardVisible (): boolean {
      return this.applicationData.secondChoice != null || this.applicationData.thirdChoice != null
    },

    addSecondChoiceButtonVisible (): boolean {
      if (this.applicationData.firstChoice) {
        return !this.secondChoiceCardVisible
      }
      return false
    },

    thirdChoiceCardVisible (): boolean {
      return this.applicationData.thirdChoice != null
    },

    addThirdChoiceButtonVisible (): boolean {
      if (this.applicationData.firstChoice) {
        return this.secondChoiceCardVisible && !this.thirdChoiceCardVisible
      }
      return false
    },

    lands(): IdNamePair[] {
      const nationality = this.nationalities.find( n => n.id === this.applicationData.nationalityId )
      if (nationality) {
        return nationality.lands || []
      }
      return []
    },

    requireIdNumber(): boolean {
      return this.selectedNcp?.requireIdNumber || false
    },

    canChangeDetails(): boolean {
      return this.applicationData.firstChoice == null
    },

    selectedNationality(): OspeNationality | null {
      return this.nationalities.find( n => n.id === this.applicationData.nationalityId ) || null
    },

    ncps(): OspeNcp[] {
      return this.selectedNationality?.ncps || []
    },

    selectedNcp(): OspeNcp | null {
      return this.selectedNationality?.ncps?.find( n => n.id == this.applicationData.ncpId ) || null
    },

    selectedFunction(): OspeShortTermAvailableFunction | null {
      return this.availableFunctions.find( fn => fn.id == this.applicationData.functionId ) || null
    },

    selectedCourtStaffWorkplace(): OspeShortTermAvailableCourtStaffWorkplace | null {
      return this.availableCourtStaffWorkplaces.find(wp => wp.id == this.applicationData.courtStaffWorkplaceId) || null
    },

    selectCourtStaffworkplaceVisible(): boolean {
      return this.availableCourtStaffWorkplaces.length > 0 || false
    },

    availableCourtStaffWorkplaces(): OspeShortTermAvailableCourtStaffWorkplace[] {
      return this.selectedFunction?.courtStaffWorkplaces || []
    },

    availableActivityCategories(): OspeShortTermAvailableCategory[] {
      let allCategories = this.selectedFunction?.categories || []

      const currentCourtStaffWorkplace = this.selectedCourtStaffWorkplace
      if (currentCourtStaffWorkplace) {
        allCategories = allCategories.filter( c => c.courtStaffWorkplaceIds.includes(currentCourtStaffWorkplace.id) )
      } else {
        if (this.requireCourtStaffWorkplace) { // function has court staff workplaces, but none selected
          return []
        }
      }
      return allCategories
    },

    availableSpecialisations(): OspeShortTermAvailableSpecialisation[] {
      const currentCategory = this.selectedActivityCategory
      if (!currentCategory) {
        return []
      }

      if (this.requireCourtStaffWorkplace) {
        const currentCourtStaffWorkplace = this.selectedCourtStaffWorkplace
        if (currentCourtStaffWorkplace == null) {
          return []
        }

        return currentCategory.specialisations.filter(c => c.courtStaffWorkplaceIds.includes(currentCourtStaffWorkplace.id))
      }

      return currentCategory.specialisations
    },

    requireCourtStaffWorkplace(): boolean {
      return (this.selectedFunction?.courtStaffWorkplaces?.length || 0) > 0
    },

    showInstructions(): boolean {
      return this.currentInstructions != null
    },

    currentInstructions(): OspeShortTermActivityCategoryInstructions | null {
      const currentCategory = this.selectedActivityCategory
      if (!currentCategory) {
        return null
      }
      return this.instructions[currentCategory.id.toString()] || null
    },

    instructionsHtml(): string | null {
      return this.currentInstructions?.html || null
    },

    instructionsFiles(): OspeProjectBasedActivityTemplateFile[] {
      return this.currentInstructions?.files || []
    },

    filteredExchanges(): ExchangePlace[] {
      const selectedCountries = filterItems(this.countryFilter)
      const selectedCategories = filterItems(this.categoryFilter)
      const selectedTypes = filterItems(this.typeFilter)
      const selectedLanguages = filterItems(this.languageFilter)
      const selectedFunctions = filterItems(this.functionFilter)
      const selectedSpecialisations = filterItems(this.specialisationFilter)
      const selectedDurations = filterItems(this.durationFilter)

      return this.availableExchanges.filter((f) => {
        if (selectedCountries.length > 0 && !selectedCountries.includes(f.countryName)) {
          return false
        }

        if (selectedCategories.length > 0 && !selectedCategories.includes(f.category)) {
          return false
        }

        if (selectedTypes.length > 0) {
          const exchangeTypes = (f.exchangeType || '').split(', ')
          if (!selectedTypes.find( l => exchangeTypes.includes(l) )) {
            return false
          }
        }

        if (selectedLanguages.length > 0) {
          const exchangeLanguages = f.languages.split(', ')
          if (!selectedLanguages.find( l => exchangeLanguages.includes(l) )) {
            return false
          }
        }

        if (selectedFunctions.length > 0) {
          const exchangeFunctions = f.functions.split(', ')
          if (!selectedFunctions.find( l => exchangeFunctions.includes(l) )) {
            return false
          }
        }

        if (selectedSpecialisations.length > 0 && !selectedSpecialisations.includes(f.specialisation || '')) {
          return false
        }

        if (selectedDurations.length > 0) {
          const exchangeDurations = f.duration.split(', ')
          if (!selectedDurations.find( l => exchangeDurations.includes(l) )) {
            return false
          }
        }

        return true
      })
    },

    filteredSelectExchangesList(): ExchangeChoice[] {
      const selectedCountries = filterItems(this.selectCountryFilter)
      const selectedTypes = filterItems(this.selectTypeFilter)
      const selectedLanguages = filterItems(this.selectLanguageFilter)
      const selectedFunctions = filterItems(this.selectFunctionFilter)
      const selectedSpecialisations = filterItems(this.selectSpecialisationFilter)
      const selectedDurations = filterItems(this.selectDurationFilter)

      const selectedPlaceIds = [
        this.applicationData.firstChoice?.id,
        this.applicationData.secondChoice?.id,
        this.applicationData.thirdChoice?.id
      ].filter( v => v != null ).map( v => v?.toString() || '')

      return this.selectExchangesList.filter((f) => {
        if (f.activityId && selectedPlaceIds.includes(f.id.toString())) {
          return false // don't show activities already selected for a choice
        }

        if (selectedCountries.length > 0 && !selectedCountries.includes(f.countryName)) {
          return false
        }

        if (selectedTypes.length > 0) {
          const exchangeTypes = (f.exchangeType || '').split(', ')
          if (!selectedTypes.find( l => exchangeTypes.includes(l) )) {
            return false
          }
        }

        if (selectedLanguages.length > 0) {
          const exchangeLanguages = f.languages.split(', ')
          if (!selectedLanguages.find( l => exchangeLanguages.includes(l) )) {
            return false
          }
        }

        if (selectedFunctions.length > 0) {
          const exchangeFunctions = f.functions.split(', ')
          if (!selectedFunctions.find( l => exchangeFunctions.includes(l) )) {
            return false
          }
        }

        if (selectedSpecialisations.length > 0 && !selectedSpecialisations.includes(f.specialisation || '')) {
          return false
        }

        if (selectedDurations.length > 0) {
          const exchangeDurations = f.duration.split(', ')
          if (!selectedDurations.find( l => exchangeDurations.includes(l) )) {
            return false
          }
        }

        return true
      })
    }
  },
  watch: {
    applicationData: {
      deep: true,

      // We have to move our method to a handler field
      handler(): void {
        this.storeApplicationData()
      }
    },
    'applicationData.functionId': function(): void {
      const currentFunction = this.selectedFunction
      if (currentFunction) {
        const hasCourtStaffWorkplace = currentFunction.courtStaffWorkplaces.some(v => v.id == this.applicationData.courtStaffWorkplaceId)
        if (!hasCourtStaffWorkplace) { // reset court staff workplace
          this.applicationData.courtStaffWorkplaceId = null
        }
      }

      const category = this.availableActivityCategories.find(v => v.id == this.applicationData.activityCategoryId)
      this.applicationData.activityCategoryId = category?.id || null
    },
  },
  mounted () {
    const exchangesDataEl = document.querySelector('script[ref="exchangeSelectionData"]')
    if (exchangesDataEl instanceof HTMLScriptElement && exchangesDataEl.type == 'application/json') {
      try {
        const exchangeData = JSON.parse(exchangesDataEl.innerText)
        if (Array.isArray(exchangeData.functions)) {
          this.availableFunctions = exchangeData.functions
        }
        if (Array.isArray(exchangeData.activityTypes)) {
          this.activityTypes = exchangeData.activityTypes
        }
        if (Array.isArray(exchangeData.availableExchanges)) {
          this.availableExchanges = exchangeData.availableExchanges
          this.updateAvailableExchangesFilters()
        }
        if (Array.isArray(exchangeData.nationalities)) {
          this.nationalities = exchangeData.nationalities
        }
        if (exchangeData.instructions) {
          this.instructions = exchangeData.instructions
        }
      }
      catch {
      }
    }
    this.applicationDataEl = document.querySelector('input[ref="applicationDataJson"]')
    if (this.applicationDataEl) {
      try {
        this.applicationData = JSON.parse(this.applicationDataEl.value)
      } catch {
      }
    }
  },
  methods: {
    showSelectExchangeModal(choiceIdx: number): void {
      if (!this.selectedActivityCategory) {
        this.$refs.modalAlert.show(this.$t('application_form.select_exchange.error.no_exchange_selected.title'), this.$t('application_form.select_exchange.error.no_exchange_selected.message'), 'danger')
        return
      }

      if (this.requireSpecialisation && !this.hasSpecialisation) {
        this.$refs.modalAlert.show(this.$t('application_form.select_exchange.error.no_specialisation_selected.title'), this.$t('application_form.select_exchange.error.no_specialisation_selected.message'), 'danger')
        return
      }

      if (!this.hasFunction) {
        this.$refs.modalAlert.show(this.$t('application_form.select_exchange.error.no_function_selected.title'), this.$t('application_form.select_exchange.error.no_function_selected.message'), 'danger')
        return
      }

      if (this.requireCourtStaffWorkplace && !this.applicationData.courtStaffWorkplaceId) {
        this.$refs.modalAlert.show(this.$t('application_form.select_exchange.error.no_court_staff_workplace_selected.title'), this.$t('application_form.select_exchange.error.no_court_staff_workplace_selected.message'), 'danger')
        return
      }

      if (!this.hasNationality) {
        this.$refs.modalAlert.show(this.$t('application_form.select_exchange.error.no_nationality_selected.title'), this.$t('application_form.select_exchange.error.no_nationality_selected.message'), 'danger')
        return
      }

      this.selectExchangesListLoaded = false
      this.exchangeSelectionIdx = choiceIdx

      this.$refs.selectExchangeModal.showModal(this.availableExchangesUrl, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        activity_category_id: this.selectedActivityCategory.id,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        specialisation_id: this.applicationData.specialisationId,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        function_id: this.applicationData.functionId,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        court_staff_workplace_id: this.applicationData.courtStaffWorkplaceId,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        except_nationality_id: this.applicationData.nationalityId,
      })
    },

    onSelectExchangesLoaded(data: any): void {
      // this.selectExchangesList.push({country: 'xxx'})
      this.selectExchangesListLoaded = true
      const loadedData = new Jsona().deserialize(data)
      if (Array.isArray(loadedData)) {
        this.selectExchangesList = loadedData as ExchangeChoice[]
        this.updateSelectExchangesListFilters()
      }
    },

    selectExchange(exchange: ExchangeChoice | null, exchangeIdx: number): void {
      this.$refs.selectExchangeModal.hideModal()
      let previousExchange = null
      let newExchange = null

      if (exchange) {
        switch(exchangeIdx) {
          case 0:
            previousExchange = this.applicationData.firstChoice
            break;
          case 1:
            previousExchange = this.applicationData.secondChoice
            break;
          case 2:
            previousExchange = this.applicationData.thirdChoice
            break;
        }
        newExchange = this.prepareExchange(exchange, previousExchange)
      }

      switch(exchangeIdx) {
        case 0:
          this.applicationData.firstChoice = newExchange
          break;
        case 1:
          this.applicationData.secondChoice = newExchange
          break;
        case 2:
          this.applicationData.thirdChoice = newExchange
          break;
      }
    },

    prepareExchange(exchange: ExchangeChoice, previousExchange: ExchangeChoice | null): ExchangeChoice {
      // copy exchange
      exchange = JSON.parse(JSON.stringify(exchange))

      if (previousExchange) {
        if (previousExchange.exchangeTypeId) {
          const typeExists = exchange.exchangeTypeItems.some(x => x.id == previousExchange.exchangeTypeId)
          if (typeExists) {
            exchange.exchangeTypeId = previousExchange.exchangeTypeId
          }
        }
        if (previousExchange.languageId) {
          const languageExists = exchange.languageItems.some(x => x.id == previousExchange.languageId)
          if (languageExists) {
            exchange.languageId = previousExchange.languageId
          }
        }
        if (previousExchange.durationId) {
          const durationExists = exchange.durationItems.some(x => x.id == previousExchange.durationId)
          if (durationExists) {
            exchange.durationId = previousExchange.durationId
          }
        }
        exchange.preferredPeriod = previousExchange.preferredPeriod
      }
      return exchange
    },

    storeApplicationData(): void {
      if (this.applicationDataEl) {
        this.applicationDataEl.value = JSON.stringify(this.applicationData)
      }
    },

    showDeleteExchangePrompt(exchangeIdx: number): void {
      this.$refs.removeChoiceConfirmationModal.show(null, {
        title: this.$t('application_form.select_exchange.remove_choice_confirmation.title'),
        message: this.$t('application_form.select_exchange.remove_choice_confirmation.message'),
        type: 'danger',
        cancelActionTitle: this.$t('application_form.select_exchange.remove_choice_confirmation.cancel_action_title'),
        confirmActionTitle: this.$t('application_form.select_exchange.remove_choice_confirmation.confirm_action_title'),
        confirmationCode: null,
        context: exchangeIdx
      })
    },

    onRemoveChoiceConfirmed(exchangeIdx?: number): void {
      this.$refs.removeChoiceConfirmationModal.hide()
      switch (exchangeIdx) {
        case 0:
          this.applicationData.firstChoice = this.applicationData.secondChoice
        case 1:
          this.applicationData.secondChoice = this.applicationData.thirdChoice
        case 2:
          this.applicationData.thirdChoice = null
      }
    },

    updateAvailableExchangesFilters(): void {
      const countries: string[] = []
      const categories: string[] = []
      let types: string[] = []
      let languages: string[] = []
      let functions: string[] = []
      const specialisations: string[] = []
      let durations: string[] = []

      for(const exchange of this.availableExchanges) {
        countries.push(exchange.countryName)
        categories.push(exchange.category)
        types = types.concat((exchange.exchangeType || '').split((', ')))
        languages = languages.concat(exchange.languages.split(', '))
        functions = functions.concat(exchange.functions.split(', '))
        specialisations.push(exchange.specialisation || '')
        durations = durations.concat(exchange.duration.split(', '))
      }

      this.countryFilter = createFilter(countries)
      this.categoryFilter = createFilter(categories)
      this.typeFilter = createFilter(types)
      this.languageFilter = createFilter(languages)
      this.functionFilter = createFilter(functions)
      this.specialisationFilter = createFilter(specialisations)
      this.durationFilter = createFilter(durations)
    },

    updateSelectExchangesListFilters(): void {
      const countries: string[] = []
      let types: string[] = []
      let languages: string[] = []
      let functions: string[] = []
      const specialisations: string[] = []
      let durations: string[] = []

      for(const exchange of this.selectExchangesList) {
        countries.push(exchange.countryName)
        types = types.concat((exchange.exchangeType || '').split(', '))
        languages = languages.concat(exchange.languages.split(', '))
        functions = functions.concat(exchange.functions.split(', '))
        specialisations.push(exchange.specialisation || '')
        durations = durations.concat(exchange.duration.split(', '))
      }

      this.selectCountryFilter = createFilter(countries)
      this.selectTypeFilter = createFilter(types)
      this.selectLanguageFilter = createFilter(languages)
      this.selectFunctionFilter = createFilter(functions)
      this.selectSpecialisationFilter = createFilter(specialisations)
      this.selectDurationFilter = createFilter(durations)
    },

    onFilterChanged(filter: GridFilter, filterValue: string | string[]): void {
      filter.selectedValue = filterValue
    },

    filterTitle(filter: GridFilter): string {
      const title = filter.selectedTitle
      if (isNonEmptyString(title)) {
        return title
      }

      return 'All'
    }
  }
})
</script>
