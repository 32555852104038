<template>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
      <tr>
        <th>{{ $t('short_term_application.available_exchanges.column.country') }}</th>
        <th>{{ $t('short_term_application.available_exchanges.column.category') }}</th>
        <th>{{ $t('short_term_application.available_exchanges.column.languages') }}</th>
        <th>{{ $t('short_term_application.available_exchanges.column.functions') }}</th>
        <th>{{ $t('short_term_application.available_exchanges.column.available_places') }}</th>
        <th>{{ $t('short_term_application.available_exchanges.column.length') }}</th>
        <th>{{ $t('short_term_application.available_exchanges.column.specialisation') }}</th>
        <th>{{ $t('short_term_application.available_exchanges.column.additional_information') }}</th>
      </tr>
      </thead>
      <tbody>
        <tr class="filter no-hover">
          <td>
            <grid-filter-list
              :value="countryFilter"
              :show-dropdown-caret="false"
              @input="onFilterChanged(countryFilter, $event)"
            >
              {{ filterTitle(countryFilter) }}
            </grid-filter-list>
          </td>
          <td>
            <grid-filter-list
              :value="categoryFilter"
              :show-dropdown-caret="false"
              @input="onFilterChanged(categoryFilter, $event)"
            >
              {{ filterTitle(categoryFilter) }}
            </grid-filter-list>
          </td>
          <td>
            <grid-filter-list
              :value="languageFilter"
              :show-dropdown-caret="false"
              @input="onFilterChanged(languageFilter, $event)"
            >
              {{ filterTitle(languageFilter) }}
            </grid-filter-list>
          </td>
          <td>
            <grid-filter-list
              :value="functionFilter"
              :show-dropdown-caret="false"
              @input="onFilterChanged(functionFilter, $event)"
            >
              {{ filterTitle(functionFilter) }}
            </grid-filter-list>
          </td>
          <td></td>
          <td>
            <grid-filter-list
              :value="durationFilter"
              :show-dropdown-caret="false"
              @input="onFilterChanged(durationFilter, $event)"
            >
              {{ filterTitle(durationFilter) }}
            </grid-filter-list>
          </td>
          <td>
            <grid-filter-list
              :value="specialisationFilter"
              :show-dropdown-caret="false"
              @input="onFilterChanged(specialisationFilter, $event)"
            >
              {{ filterTitle(specialisationFilter) }}
            </grid-filter-list>
          </td>
          <td></td>
        </tr>
        <tr
          v-for="place of filteredPlaces"
          :key="place.id"
        >
          <td>{{ place.country }}</td>
          <td>{{ place.category }}</td>
          <td>{{ place.languages }}</td>
          <td>{{ place.functions }}</td>
          <td>{{ place.places }}</td>
          <td>{{ place.length }}</td>
          <td>{{ place.specialisation }}</td>
          <td>{{ place.additionalInformation }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import GridFilterList from "@/js/vue-components/grid-filter-list.vue";
import { GridFilter } from "@/js/vue-components/types";
import {createFilter, filterItems} from "@/js/vue-components/filter-utils";
import {isNonEmptyString} from "@/js/js-utils";


interface AvailablePlace {
  id: string;
  additionalInformation: string
  category: string;
  country: string;
  exchangeType: string;
  functions: string;
  languages: string;
  length: string;
  places: number;
  specialisation: string;
}

export default defineComponent({
  components: { GridFilterList },
  props: {
    availablePlaces: { default: (): AvailablePlace[] => [], type: Array as () => AvailablePlace[] },
  },

  data() {
    return {
      countryFilter: createFilter([]),
      categoryFilter: createFilter([]),
      languageFilter: createFilter([]),
      functionFilter: createFilter([]),
      durationFilter: createFilter([]),
      specialisationFilter: createFilter([]),
    }
  },

  computed: {
    filteredPlaces (): AvailablePlace[] {
      const selectedCountries = filterItems(this.countryFilter)
      const selectedCategories = filterItems(this.categoryFilter)
      const selectedLanguages = filterItems(this.languageFilter)
      const selectedFunctions = filterItems(this.functionFilter)
      const selectedDurations = filterItems(this.durationFilter)
      const selectedSpecialisations = filterItems(this.specialisationFilter)

      return this.availablePlaces.filter((f) => {
        if (selectedCountries.length > 0 && !selectedCountries.includes(f.country)) {
          return false
        }

        if (selectedCategories.length > 0 && !selectedCategories.includes(f.category)) {
          return false
        }

        if (selectedLanguages.length > 0) {
          const placeLanguages = f.languages.split(', ')
          if (!selectedLanguages.find( l => placeLanguages.includes(l) )) {
            return false
          }
        }

        if (selectedFunctions.length > 0) {
          const placeFunctions = f.functions.split(', ')
          if (!selectedFunctions.find( l => placeFunctions.includes(l) )) {
            return false
          }
        }

        if (selectedDurations.length > 0) {
          const placeDurations = f.length.split(', ')
          if (!selectedDurations.find( l => placeDurations.includes(l) )) {
            return false
          }
        }

        if (selectedSpecialisations.length > 0 && !selectedSpecialisations.includes(f.specialisation || '')) {
          return false
        }

        return true
      })
    }
  },
  mounted() {
    this.updateAvailablePlacesFilters()
  },
  methods: {
    updateAvailablePlacesFilters(): void {
      const countries: string[] = []
      const categories: string[] = []
      let languages: string[] = []
      let functions: string[] = []
      const specialisations: string[] = []
      let durations: string[] = []

      for(const place of this.availablePlaces) {
        countries.push(place.country)
        categories.push(place.category)
        languages = languages.concat(place.languages.split(', '))
        functions = functions.concat(place.functions.split(', '))
        specialisations.push(place.specialisation)
        durations = durations.concat(place.length.split(', '))
      }

      this.countryFilter = createFilter(countries)
      this.categoryFilter = createFilter(categories)
      this.languageFilter = createFilter(languages)
      this.functionFilter = createFilter(functions)
      this.specialisationFilter = createFilter(specialisations)
      this.durationFilter = createFilter(durations)
    },

    onFilterChanged(filter: GridFilter, filterValue: string | string[]): void {
      filter.selectedValue = filterValue
    },

    filterTitle(filter: GridFilter): string {
      const title = filter.selectedTitle
      if (isNonEmptyString(title)) {
        return title
      }

      return 'All'
    }
  }
})

</script>
