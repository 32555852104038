<template>
  <div>
    <div
      v-if="files.length > 0"
      class="list-group list-group-flush files-list col-md-6"
    >
      <div
        v-for="(file, fileIdx) of files"
        :key="file.id"
        class="list-group-item"
      >
        <div class="form-row">
          <div class="col col-12 col-md form-group">
            <label :class="{ required: file.isRequired }">{{ fileLabel(file) }}</label>
            <input
              v-if="file.isStatic"
              type="text"
              class="form-control form-control-sm"
              readonly="readonly"
              :value="file.filename"
            >
            <input
              v-else
              v-model="file.title"
              type="text"
              class="form-control form-control-sm"
              :placeholder="file.filename"
            >
          </div>
          <div class="col col-tight form-group">
            <label>&nbsp;</label>
            <div>
              <template v-if="file.isStatic">
                <button
                  v-if="staticFileIsPresent(file)"
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click.prevent="showRemoveFilePrompt(fileIdx)"
                >
                  Delete
                </button>
                <label
                  v-else
                  :class="['btn btn-sm btn-success with-upload-icon text-nowrap', { 'disabled': !canUpload || isUploading }]"
                >
                  {{ $t('application_files.add_static_file') }}
                  <input
                    type="file"
                    style="display: none"
                    :disabled="!canUpload || isUploading"
                    @change="uploadApplicationFile($event, fileIdx, file)"
                  >
                </label>
              </template>
              <button
                v-else
                type="button"
                class="btn btn-sm btn-danger"
                @click.prevent="showRemoveFilePrompt(fileIdx)"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <label :class="['btn btn-sm btn-success', { 'with-upload-icon': !isUploading, 'disabled': !canUpload || isUploading }]">
      <template v-if="isUploading">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
        {{ uploadProgressMessage }}
      </template>
      <template v-else>
        {{ $t('application_files.add_file') }}
        <input
          type="file"
          style="display: none"
          :disabled="!canUpload || isUploading"
          @change="uploadApplicationFile"
        >
      </template>
    </label>

    <alert
      :message="uploadError"
      @dismiss="uploadError = null"
    />

    <teleport
      to="#vue-portal-target"
      append
    >
      <modal-confirmation-rails
        ref="removeFileConfirmationModal"
        modal-title="Delete file?"
        @confirmed="onRemoveFileConfirmed"
      />
    </teleport>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
  import axios from 'axios'
  import Rails from '@rails/ujs'
  import { isNonEmptyString } from '../../js-utils'

  import ModalConfirmationRails from '../../vue-components/modal-confirmation-rails.vue'
  import Alert from '../../vue-components/alert.vue'

  interface UploadFile {
    id: string;
    title: string;
    filename: string;
    isStatic: boolean;
    isRequired: boolean;
    type: string | null;
  }

  export default defineComponent({
    name: 'ApplicationFiles',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    components: { Alert, ModalConfirmationRails },
    props: {
      numberOfFiles: { type: Number, default: 5 },
      uploadUrl: { type: String, default: null },
    },
    data () {
      return {
        isUploading: false,
        uploadProgress: 0,
        uploadProgressMessage: '',
        uploadError: null as string | null,
        files: [] as UploadFile[],
        filesJsonEl: null as null | HTMLInputElement
      }
    },
    computed: {
      canUpload (): boolean {
        return typeof this.uploadUrl === 'string' && this.uploadUrl.length > 0
      }
    },
    watch: {
      files: {
        deep: true,

        // We have to move our method to a handler field
        handler(): void {
          this.storeApplicationFiles()
        }
      }
    },
    mounted () {
      const jsonEl = document.querySelector('input[ref="applicationFiles"]')
      if (jsonEl instanceof HTMLInputElement) {
        this.filesJsonEl = jsonEl
        try {
          const filesData = JSON.parse(this.filesJsonEl.value)
          if (Array.isArray(filesData)) {
            this.files = filesData
          }
        } catch {
        }
      }
    },
    methods: {
      uploadApplicationFile (e: Event, fileIdx?: number, uploadFile?: UploadFile): void {
        if (e.target instanceof HTMLInputElement) {
          this.uploadFile(e.target, fileIdx, uploadFile)
        }
      },

      uploadFile (uploadField: HTMLInputElement, fileIdx?: number, file?: UploadFile): void {
        if (!this.canUpload) {
          return
        }

        if (!uploadField.files) {
          return
        }

        const uploadFile = uploadField.files.item(0)
        if (uploadFile === null) {
          // IE 11 will fire change event when setting uploadField.value to ''
          // if file is null, then do not anything
          return
        }

        const formData = new FormData()
        formData.append('file', uploadFile)
        if (file && isNonEmptyString(file.type)) {
          formData.append('type_id', file.type)
        }

        // IE 11 will fire change event again
        uploadField.value = ''

        this.isUploading = true
        this.uploadProgress = 0
        this.uploadProgressMessage = 'Uploading file ...'
        this.uploadError = null

        axios.post(this.uploadUrl, formData, {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'X-Requested-With': 'XMLHttpRequest',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'X-CSRF-Token': Rails.csrfToken()
          },
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.floor(progressEvent.loaded / progressEvent.total * 100)
            if (progressEvent.loaded === progressEvent.total) {
              this.uploadProgressMessage = 'Processing file ...'
            } else {
              this.uploadProgressMessage = `Uploading file: ${this.uploadProgress} %`
            }
          }
        })
          .then((response) => {
            if (response.data) {
              if (response.data.file) {
                if (fileIdx != null && fileIdx >= 0 && fileIdx <= this.files.length) {
                  const file = this.files[fileIdx]
                  file.id = response.data.file.id
                  file.filename = response.data.file.filename
                } else {
                  // check if we have a file with the same id
                  const fileId = response.data.file.id
                  let fileUpdated = false
                  for (let i = 0; i < this.files.length; i++) {
                    if (this.files[i].id === fileId) {
                      this.files.splice(i, 1, response.data.file)
                      fileUpdated = true
                    }
                  }
                  if (!fileUpdated) {
                    this.files.push(response.data.file)
                  }
                }
              } else if (response.data.error) {
                this.uploadError = response.data.error
              } else {
                this.uploadError = 'Invalid response from server'
              }
            }
            this.isUploading = false
          })
          .catch((error) => {
            this.uploadError = error.message
            if (error.response && error.response.data && isNonEmptyString(error.response.data.error)) {
              this.uploadError = error.response.data.error
            }
            this.isUploading = false
          })
      },

      storeApplicationFiles (): void {
        if (this.filesJsonEl) {
          this.filesJsonEl.value = JSON.stringify(this.files)
        }
      },

      showRemoveFilePrompt(fileIdx: number): void {
        this.$refs.removeFileConfirmationModal.show(null, {
          title: 'Remove file?',
          message: 'Are you sure you want to remove this file?',
          type: 'danger',
          cancelActionTitle: 'Cancel',
          confirmActionTitle: 'Remove',
          confirmationCode: null,
          context: fileIdx
        })
      },

      onRemoveFileConfirmed(fileIdx?: number): void {
        this.$refs.removeFileConfirmationModal.hide()

        if (fileIdx != null && fileIdx >= 0 && fileIdx < this.files.length) {
          const file = this.files[fileIdx]
          if (file.isStatic) {
            // for static files clear content
            file.id = ''
            file.filename = ''
          } else {
            this.files.splice(fileIdx, 1)
          }
        }
      },

      fileLabel (file: UploadFile): string {
        if (file.isStatic) {
          return file.title
        }
        return 'File description'
      },

      staticFileIsPresent (file: UploadFile): boolean {
        return isNonEmptyString(file.id)
      }
    }
  })
</script>
