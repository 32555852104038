import axios from 'axios'
import Rails from '@rails/ujs'

import { isNonEmptyString } from '../js-utils'
import { getAxiosError } from '../axios-utils'

export interface UploadConfig {
  additionalUploadData?: Record<string, string | Blob>;
  startCallback?: () => void;
  progressCallback?: (progress: number, message: string) => void;
}

export function uploadFile(uploadUrl: string, uploadFile: File, uploadConfig?: UploadConfig): Promise<any> {
  const formData = new FormData()

  if (uploadConfig?.additionalUploadData) {
    for (const paramName in uploadConfig.additionalUploadData) {
      if (uploadConfig.additionalUploadData.hasOwnProperty(paramName)) {
        formData.append(paramName, uploadConfig.additionalUploadData[paramName])
      }
    }
  }

  formData.append('file', uploadFile)

  const startCB = uploadConfig?.startCallback
  if (startCB) {
    startCB()
  }

  const progressCB = uploadConfig?.progressCallback
  if (progressCB) {
    progressCB(0, 'Uploading file ...')
  }

  const uploadPromise = new Promise<string>((resolutionFn, rejectionFn) => {
    axios.post(uploadUrl, formData, {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-Requested-With': 'XMLHttpRequest',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-CSRF-Token': Rails.csrfToken()
      },
      onUploadProgress: (progressEvent) => {
        if (progressCB) {
          const uploadProgress = Math.floor(progressEvent.loaded / progressEvent.total * 100)
          const progressMessage = progressEvent.loaded === progressEvent.total ? 'Processing file ...' : 'Uploading file ...'
          progressCB(uploadProgress, progressMessage)
        }

      }
    })
      .then((response) => {
        if (isNonEmptyString(response.data?.error)) {
          rejectionFn(response.data.error)
        } else {
          resolutionFn(response.data)
        }
      })
      .catch((error) => {
        const errorMessage = getAxiosError(error)
        rejectionFn(errorMessage)
      })
    })

    return uploadPromise
}
