import {type UploadConfig, uploadFile} from "@/js/lib/uploader";
import {isNonEmptyString} from "@/js/js-utils";

export enum ReimbursementFileType {
  // File = 0,
  // ParticipationCertificate = 1,
  completionCertificate = 2,
  // MidTermCertificate = 3,
  agendaOrProgramme = 4,
  financeExceptionRequest = 5,
}

export interface ReimbursementFile {
  id: string;
  typeId: ReimbursementFileType;
  title: string;
  filename: string;
  editable: boolean;
  deleted: boolean;
}

export function reimbursementFileUpload(
  uploadField: HTMLInputElement,
  uploadUrl: string,
  typeId: ReimbursementFileType,
  uploadConfig?: UploadConfig
): Promise<ReimbursementFile> {
  const fileToUpload = uploadField?.files?.item(0)
  console.log('Upload file', uploadUrl, typeId, fileToUpload)
  if (fileToUpload == null) {
    // IE 11 will fire change event when setting uploadField.value to ''
    // if file is null, then do not anything
    return Promise.reject('No file selected')
  }

  // IE 11 will fire change event again
  uploadField.value = ''

  uploadConfig = uploadConfig || {}
  uploadConfig.additionalUploadData = uploadConfig.additionalUploadData || {}
  uploadConfig.additionalUploadData['type_id'] = typeId.toString()

  return new Promise<ReimbursementFile>((resolve, reject) => {
    console.log('starting upload ....', uploadUrl)
    uploadFile(uploadUrl, fileToUpload, uploadConfig)
      .then((value) => {
        const fileData = value.file
        console.log("fileData", fileData, value)
        resolve({
          id: fileData.id,
          typeId: fileData.typeId,
          title: fileData.title,
          filename: fileData.filename,
          editable: fileData.editable,
          deleted: fileData.deleted,
        })
      })
      .catch((reason: any) => {
        if (isNonEmptyString(reason)) {
          reject(reason)
        } else {
          reject('An error occurred')
        }
      })
  })
}
