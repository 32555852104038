<script lang="ts" setup>
import {computed, onMounted, ref, watch} from 'vue'
import Jsona from 'jsona'

import {useTranslation} from "i18next-vue"
import {isNonEmptyString} from '@/js/js-utils'
import {uploadFile} from '@/js/lib/uploader'

import ProgressIndicator from '../progress-indicator.vue'
import Alert from '../alert.vue'

const {t: $t} = useTranslation()

interface ReportFile {
  id: string;
  title: string;
  filename: string;
  editable: boolean;
  deleted: boolean;
}

const props = defineProps({
  uploadUrl: {default: '', type: String},
  filesFieldRef: {default: '', type: String},
  title: {default: null, type: String},
  noFilesMessage: {default: null, type: String}
})

const files = ref<ReportFile[]>([])
const isUploading = ref(false)
const uploadProgress = ref(0)
const uploadMessage = ref('')
const uploadError = ref('')
const filesValueField = ref<HTMLInputElement | null>(null)

const canUpload = computed(() => {
  return isNonEmptyString(props.uploadUrl) && !isUploading.value
})

const uploadErrorVisible = computed(() => {
  return isNonEmptyString(uploadError.value)
})

const noFilesWarningMessage = computed(() => {
  return props.noFilesMessage || $t('report_submission_files_control.no_files_message') // 'Please upload at least one file'
})

const noFilesMessageVisible = computed(() => {
  return files.value.length == 0 && isNonEmptyString(noFilesWarningMessage.value)
})

watch(
  () => files,
  () => {
    if (filesValueField.value) {
      filesValueField.value.value = JSON.stringify(files.value)
    }
  },
  {deep: true}
)

onMounted(() => {
  if (isNonEmptyString(props.filesFieldRef)) {
    const vf = document.querySelector(`input[ref="${props.filesFieldRef}"]`)
    if (vf instanceof HTMLInputElement) {
      filesValueField.value = vf
      try {
        const fval = JSON.parse(filesValueField.value.value)
        if (Array.isArray(fval)) {
          files.value = fval
        }
      } catch {
      }
    }
  }
})

function uploadReportFile(e: Event): void {
  const uploadField = e.target
  if (uploadField instanceof HTMLInputElement && uploadField.files != null) {
    const reportFile = uploadField.files[0]
    if (reportFile === null) {
      // IE 11 will fire change event when setting uploadField.value to ''
      // if file is null, then do not anything
      return
    }

    // IE 11 will fire change event again
    uploadField.value = ''

    isUploading.value = true
    uploadProgress.value = 0
    uploadMessage.value = ''
    uploadError.value = ''

    uploadFile(props.uploadUrl, reportFile, {
      progressCallback: (progress, message) => {
        uploadProgress.value = progress
        uploadMessage.value = message
      }
    })
      .then((value) => {
        const fileData = new Jsona().deserialize(value)
        if (Array.isArray(fileData)) {
          uploadError.value = 'File upload failed'
        } else {
          files.value.push({
            id: fileData.id,
            title: fileData.title,
            filename: fileData.filename,
            editable: fileData.editable,
            deleted: fileData.deleted,
          })
        }
      })
      .catch((reason: any) => {
        if (isNonEmptyString(reason)) {
          uploadError.value = reason
        } else {
          uploadError.value = 'An error occurred'
        }
      })
      .then(() => {
        isUploading.value = false
      })
  }
}

function deleteFile(file: ReportFile): void {
  if (!file.editable) {
    return
  }

  if (confirm('Are you sure?')) {
    file.deleted = true
  }
}

function restoreFile(file: ReportFile): void {
  file.deleted = false
}
</script>

<template>
  <div class="report-files">
    <div class="card files">
      <div class="card-body">
        <h5 class="card-title">
          {{ title || $t('report_submission_files_control.title') }}
        </h5>

        <div
          v-if="noFilesMessageVisible"
          class="alert alert-warning"
        >
          {{ noFilesWarningMessage }}
        </div>
        <div
          v-else
          class="list-group list-group-flush mb-4"
        >
          <div
            v-for="(file, fileIdx) of files"
            :key="`report-file-${fileIdx}`"
            :class="['list-group-item', { 'list-group-item-danger': file.deleted }]"
          >
            <div class="form-row">
              <div class="col col-12 col-md form-group">
                <label>Description</label>
                <input
                  v-model="file.title"
                  :readonly="!file.editable"
                  class="form-control form-control-sm"
                  data-test-id="file-title"
                >
              </div>
              <div class="col form-group">
                <label>{{ $t('report_submission_files_control.file_name') }}</label>
                <input
                  v-model="file.filename"
                  class="form-control form-control-sm"
                  data-test-id="file-name"
                  readonly="readonly"
                  type="text"
                >
              </div>
              <div class="col col-auto form-group-button">
                <template v-if="file.editable">
                  <button
                    v-if="file.deleted"
                    class="btn btn-sm btn-info"
                    type="button"
                    @click.prevent="restoreFile(file)"
                  >
                    Restore
                  </button>
                  <button
                    v-else
                    class="btn btn-sm btn-danger"
                    type="button"
                    @click.prevent="deleteFile(file)"
                  >
                    Delete
                  </button>
                </template>
              </div>
            </div>
            <small
              v-if="file.deleted"
              class="text-danger"
            >
              File will be removed on submission
            </small>
          </div>
        </div>

        <label
          v-if="canUpload"
          :class="['btn btn-sm btn-success', { disabled: !canUpload }]"
        >
          <span class="glyphicons icon-left upload" />
          <span>{{ $t('report_submission_files_control.add_new') }}</span>
          <input
            :disabled="!canUpload"
            style="display: none;"
            type="file"
            @change="uploadReportFile"
          >
        </label>
        <progress-indicator
          v-if="isUploading"
          :message="uploadMessage"
          :progress="uploadProgress"
        />
        <alert
          v-if="uploadErrorVisible"
          :dismissable="true"
          :message="uploadError"
          additional-css-class="mt-2"
          @dismiss="uploadError = ''"
        />
      </div>
    </div>
  </div>
</template>
