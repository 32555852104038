<script setup lang="ts">
import {computed, ref} from 'vue'
import {
  type ReimbursementFile,
  ReimbursementFileType,
  reimbursementFileUpload
} from "@/js/vue-components/ospe/reimbursement-file";
import {isNonEmptyString} from "@/js/js-utils";
import ProgressIndicator from "@/js/vue-components/progress-indicator.vue";
import Alert from "@/js/vue-components/alert.vue";

const props = defineProps({
  uploadUrl: { default: null, type: String },
  file: {default: null, type: Object as () => ReimbursementFile | null},
  required: {default: false, type: Boolean},
})

const emit = defineEmits<{
  (e: 'file-uploaded', file: ReimbursementFile): void
  (e: 'delete-file', file: ReimbursementFile): void
  (e: 'restore-file', file: ReimbursementFile): void
}>()

const reimbursementFileField = ref<HTMLInputElement | null>(null)
const isUploading = ref(false)
const uploadError = ref<string | null>(null)
const uploadProgress = ref(0)
const uploadMessage = ref<string | null>(null)

const withUpload = computed(() => isNonEmptyString(props.uploadUrl))
const canUpload = computed(() => true)
const uploadErrorVisible = computed(() => isNonEmptyString(uploadError.value))

const uploadAgendaFile = () => {
  const field = reimbursementFileField.value
  if (field && (field.files?.length || 0) > 0) {
    reimbursementFileUpload(
      field,
      props.uploadUrl,
      ReimbursementFileType.agendaOrProgramme,
      {
        startCallback() {
          isUploading.value = true
          uploadProgress.value = 0
          uploadMessage.value = null
          uploadError.value = null
        },
        progressCallback(progress: number, message: string) {
          uploadProgress.value = progress
          uploadMessage.value = message
        },
      }
    ).then((file) => {
      emit('file-uploaded', file)
    }).catch((error: string) => {
      uploadError.value = error
    }).then(() => {
      isUploading.value = false
    })
  }
}

const deleteFile = () => {
  if (props.file) {
    emit('delete-file', props.file)
  }
}

const restoreFile = () => {
  if (props.file) {
    emit('restore-file', props.file)
  }
}

</script>

<template>
  <div class="card files">
    <div class="card-header">
      <span class="align-middle">{{ $t('reimbursement_manager.agenda_title') }}</span>
      <label
        v-if="withUpload"
        :class="['btn btn-sm btn-primary float-right', { disabled: !canUpload }]"
      >
        <span class="glyphicons icon-left upload" /><span>upload</span>
        <input
          id="agenda-field"
          ref="reimbursementFileField"
          :disabled="!canUpload"
          style="display: none"
          type="file"
          @change="uploadAgendaFile"
        >
      </label>
    </div>
    <div
      v-if="required"
      class="card-body bg-warning"
    >
      {{ $t('reimbursement_manager.agenda_required') }}
    </div>
    <div
      v-if="file"
      :class="{ 'card-body': true, 'bg-danger-light': file.deleted }"
    >
      <div class="form-row">
        <div class="col col-12 col-md form-group">
          <label>Description</label>
          <input
            v-model="file.title"
            class="form-control form-control-sm"
            name="event_candidate[reimbursement_files_attributes][][title]"
            type="text"
            data-test-id="file-title"
          >
        </div>
        <div class="col form-group">
          <label>{{ $t('reimbursement_manager.file') }}</label>
          <input
            v-model="file.filename"
            class="form-control form-control-sm"
            readonly
            type="text"
            data-test-id="file-name"
          >
        </div>
        <div class="col col-auto form-group-button">
          <button
            v-if="!file.deleted"
            class="btn btn-sm btn-danger"
            type="button"
            @click="deleteFile"
          >
            {{ $t('reimbursement_manager.delete') }}
          </button>
          <button
            v-if="file.deleted"
            class="btn btn-sm btn-info"
            type="button"
            @click="restoreFile"
          >
            {{ $t('reimbursement_manager.restore') }}
          </button>
        </div>
      </div>
      <div
        v-if="file.deleted"
        class="text-danger font-italic"
      >
        {{ $t('reimbursement_manager.restore_notice') }}
      </div>
    </div>
    <div
      v-if="isUploading || uploadErrorVisible"
      class="card-body"
    >
      <progress-indicator
        v-if="isUploading"
        :message="uploadMessage"
        :progress="uploadProgress"
      />
      <alert
        v-if="uploadErrorVisible"
        :dismissable="true"
        :message="uploadError"
        class="mt-3 mb-0"
        @dismiss="uploadError = ''"
      />
    </div>
  </div>
</template>
