import $ from 'jquery'
import { tsInitVue } from '../js-utils'
import ReimbursementManager from '../vue-components/reimbursement-manager.vue'
import StatementOfCostsTable from '../vue-components/statement-of-costs-table.vue'
import ReportSubmissionFilesControl from '../vue-components/ospe/report-submission-files-control.vue'
import ReimbursementFilesControl from "@/js/vue-components/ospe/reimbursement-files-control.vue";

document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('body.ospe-activity-reimbursement, body.ospe-activity-expert-reimbursement')) {
    const reimbursementManagerEl = document.querySelector('div[data-is="ReimbursementManager"]')
    if (reimbursementManagerEl instanceof HTMLElement) {
      window.reimbursementManager = tsInitVue(ReimbursementManager, reimbursementManagerEl, {
        initialFiles: window.reimbursementFiles,
        uploadUrl: reimbursementManagerEl.dataset.uploadUrl,
        requireAttendanceCertificate: reimbursementManagerEl.dataset.requireAttendanceCertificate == 'true',
        withAgendaFile: reimbursementManagerEl.dataset.withAgendaFile == 'true',
        requireAgendaFile: reimbursementManagerEl.dataset.requireAgendaFile == 'true',
        filesTitle: reimbursementManagerEl.dataset.filesTitle,
        withAdditionalFiles: reimbursementManagerEl.dataset.withAdditionalFiles != 'false',
        showAgendaFileInfo: reimbursementManagerEl.dataset.showAgendaFileInfo != 'false',
      })
    }

    const statementOfCostsTableEl = document.querySelector('div[data-is="StatementOfCostsTable"]')
    if (statementOfCostsTableEl instanceof HTMLElement) {
      window.statementOfCostsTable = tsInitVue(StatementOfCostsTable, statementOfCostsTableEl, {
        itemsJsonElRef: statementOfCostsTableEl.dataset.itemsJsonElRef,
        uploadUrl: statementOfCostsTableEl.dataset.uploadUrl,
      })
    }

    const formEl = document.querySelector('form[ref="reimbursement-files-form"]')
    if (formEl && window.statementOfCostsTable) {
      formEl.addEventListener('submit', function (e) {
        if (window.statementOfCostsTable.isEditing) {
          alert('Please save the row in statements of costs table by pressing "Save this entry"')
          e.preventDefault()
          // e.stopPropagation()
          return false
        }
      })
    }

    const filesControlEls = document.querySelectorAll('div[data-is="ReportFilesControl"]')
    if (filesControlEls.length > 0) {
      window.reimbursementReportFilesControls = []
      for (let i = 0; i < filesControlEls.length; i++) {
        const el = filesControlEls[i]
        if (el instanceof HTMLElement) {
          const vueControl = tsInitVue(ReportSubmissionFilesControl, el, {
            uploadUrl: el.dataset.uploadUrl,
            filesFieldRef: el.dataset.filesFieldRef,
            title: el.dataset.title,
            noFilesMessage: el.dataset.noFilesMessage,
          })
          window.reimbursementReportFilesControls.push(vueControl)
        }
      }
    }

    const reimbursementFilesControlEl = document.querySelector('div[data-is="ReimbursementFilesControl"]')
    if (reimbursementFilesControlEl instanceof HTMLElement) {
      window.reimbursementFilesControl = tsInitVue(ReimbursementFilesControl, reimbursementFilesControlEl, {
        uploadUrl: reimbursementFilesControlEl.dataset.uploadUrl,
        valueFieldRef: reimbursementFilesControlEl.dataset.valueFieldRef,
        withCertificate: reimbursementFilesControlEl.dataset.withCertificate == 'true',
        withAgenda: reimbursementFilesControlEl.dataset.withAgenda == 'true',
        withFinanceException: reimbursementFilesControlEl.dataset.withFinanceException == 'true',
        agendaFileRequired: reimbursementFilesControlEl.dataset.agendaFileRequired == 'true',
        certificateFilesTitle: reimbursementFilesControlEl.dataset.certificateFilesTitle,
        financeExceptionFilesTitle: reimbursementFilesControlEl.dataset.financeExceptionFilesTitle,
      })
    }

    const certificateControlEl = document.querySelector('div[data-is="CertificateFilesControl"]')
    if (certificateControlEl instanceof HTMLElement) {
      window.certificateOfParticipationFilesControl = tsInitVue(ReportSubmissionFilesControl, certificateControlEl, {
        uploadUrl: certificateControlEl.dataset.uploadUrl,
        filesFieldRef: certificateControlEl.dataset.filesFieldRef,
        title: certificateControlEl.dataset.title,
      })
    }
  }
})

$(function() {
  if ($('.survey-questions').length) {
    const setRatingState = function (rc: HTMLElement, valField: HTMLInputElement): void {
      // reset state for rating buttons
      $(rc).find('button.rating-button').removeClass('btn-secondary').addClass('btn-outline-secondary')
      if (valField.value) {
        $(rc).find(`button.rating-button[data-value="${valField.value}"]`).removeClass('btn-outline-secondary').addClass('btn-secondary')
      }
    }
    for (const rc of $('.survey-questions .rating-control').toArray()) {
      const valField = $(rc).find('input[type=hidden]:first').get(0)
      if (valField instanceof HTMLInputElement) {
        setRatingState(rc, valField)
        $(rc).on('click', 'button.rating-button', function () {
          const newValue = $(this).data('value')
          if (newValue.toString() == valField.value) {
            // unset current selection
            valField.value = ''
          } else {
            // make new selection
            valField.value = newValue
          }
          setRatingState(rc, valField)
        })
      }
    }

    // handle notes field for .single-choice-control
    for (const scc of $('.survey-questions .single-choice-control').toArray()) {
      const $scc = $(scc)

      // hide all choice notes
      const $noteElements = $scc.find('.choice-note')
      $noteElements.addClass('d-none')

      // show notes for checked items
      const $selectedEl = $scc.find('input:checked')
      if ($selectedEl.length > 0) {
        $scc.find(`.choice-note[ref="note-${$selectedEl.attr('id')}"]`).removeClass('d-none')
      }

      $scc.find('input[type="radio"]').on('change', function () {
        // hide all notes fields
        $noteElements.addClass('d-none')
        if ((this as HTMLInputElement).checked) {
          // show note element if any
          $scc.find(`.choice-note[ref="note-${$(this).attr('id')}"]`).removeClass('d-none')
        }
      })
    }

    // handle notes field for .multiple-choice-control
    for (const nf of $('.survey-questions .multiple-choice-control .choice-note').toArray()) {
      const $notesField = $(nf)
      const refVal = $notesField.attr('ref') || ''

      // refVal is in form note-`check el id`
      const $checkEl = $(`#${refVal.substr('note-'.length)}`)

      if ($checkEl.is(':checked')) {
        // show notes field
        $notesField.removeClass('d-none')
      } else {
        // hide notes field
        $notesField.addClass('d-none')
      }

      $checkEl.on('change', function () {
        const $checkEl = $(this)
        if ($checkEl.is(':checked')) {
          $notesField.removeClass('d-none')
        } else {
          $notesField.addClass('d-none')
        }
      })
    }

    // instructor question control
    for (const iqc of $('.survey-questions .instructor-assessment-control .instructor').toArray()) {
      const $sq = $(iqc).find('.sub-questions:first')

      $sq.collapse({ toggle: false })

      const $cb = $(iqc).find('[ref="applicable-cb"]:first')
      if (($cb.get(0) as HTMLInputElement).checked) {
        $sq.addClass('collapse')
      } else {
        $sq.addClass('collapse show')
      }
      $cb.on('change', function () {
        $sq.collapse((this as HTMLInputElement).checked ? 'hide' : 'show')
      })
    }
  }
})
