<template>
  <div class="card choice-card">
    <div class="card-header d-flex align-items-center">
      <span class="required">{{ title }}</span>
      <button
        v-if="hasExchange"
        class="btn btn-sm btn-success ml-auto"
        @click.prevent="$emit('show-select-exchange')"
      >
        {{ $t('application_form.change_choice') }}
      </button>
    </div>
    <div class="card-body">
      <div v-if="exchange">
        <div
          v-if="hasErrorMessages"
          class="alert alert-danger"
        >
          <h4>{{ $t('application_form.errors_title') }}</h4>
          <hr>
          <ul>
            <li
              v-for="(message, messageIdx) of exchange.errorMessages"
              :key="messageIdx"
            >
              {{ message }}
            </li>
          </ul>
        </div>
        <div class="row exchange-details">
          <dl class="col col-6">
            <dt>{{ $t('study_visit_application.choice.institution_name') }}</dt>
            <dd>{{ exchange.institutionName }}</dd>
          </dl>
          <dl class="col col-3">
            <dt>{{ $t('study_visit_application.choice.dates') }}</dt>
            <dd>{{ exchange.dates }}</dd>
          </dl>
          <dl class="col col-3">
            <dt>{{ $t('study_visit_application.choice.language') }}</dt>
            <dd>{{ exchange.language }}</dd>
          </dl>
        </div>
        <hr>
        <div class="form-row">
          <label>{{ $t('study_visit_application.choice.special_interest') }}</label>
          <input
            v-model="exchange.specialInterest"
            type="text"
            class="form-control form-control-sm"
          >
        </div>
      </div>
      <div v-else>
        <p class="card-text text-muted text-center"><em>{{ $t('study_visit_application.choice_not_set_required') }}</em></p>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-sm btn-info"
            data-toggle="modal"
            data-target="#choicesModal"
            @click.prevent="$emit('show-select-exchange')"
          >
            {{ $t('study_visit_application.add_choice') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
  import type { StudyVisitPlacePeriodChoice } from '../types'

  export default defineComponent({
    name: 'StudyVisitExchangeChoiceCard',
    props: {
      title: { type: String, default: '' },
      exchange: { type: Object as () => StudyVisitPlacePeriodChoice | null, default: null },
    },
    computed: {
      hasExchange(): boolean {
        return this.exchange != null
      },

      hasErrorMessages(): boolean {
        return (this.exchange || false) && Array.isArray(this.exchange.errorMessages) && this.exchange.errorMessages.length > 0
      }
    }
  })
</script>
