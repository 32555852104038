import $ from 'jquery'

import { initializeDatePicker, isNonEmptyString, tsInitVue } from '@/js/js-utils.ts'
import { initializeMultiUploadField } from '@/js/js-vue-utils'
import ShortTermExchangeSelection from './ospe-vue-components/short-term-exchange-selection.vue'
import LongTermExchangeSelection from './ospe-vue-components/long-term-exchange-selection.vue'
import StudyVisitExchangeSelection from './ospe-vue-components/study-visit-exchange-selection.vue'
import ProjectBasedExchangeSelection from './ospe-vue-components/project-based-exchange-selection.vue'
import ApplicationFiles from './ospe-vue-components/application-files.vue'
import AvailablePlacesTable from "@/js/ospe-public/ospe-vue-components/available-places-table.vue";
import i18next from 'i18next'
import Rails from '@rails/ujs'
import ModalConfirmationRails from '../vue-components/modal-confirmation-rails.vue'

// custom Rails.confirm handling if confirmation code is present
// https://github.com/rails/rails/pull/32404#commitcomment-30083492

Rails.confirm = function(message, element): boolean {
  if (element.dataset.method == 'DELETE' && isNonEmptyString(element.dataset.confirm)) {
    if (!window.confirmationModalRails) {
      const confirmationModalEl = document.createElement('div')
      confirmationModalEl.id = 'delete-confirmation-modal-rails-wrap'
      window.document.body.appendChild(confirmationModalEl)
      window.confirmationModalRails = tsInitVue(ModalConfirmationRails, confirmationModalEl)
    }

    const confirmationCfg = {
      title: element.dataset.confirmTitle || 'Action confirmation',
      message: element.dataset.confirm || 'Are you sure?',
      type: element.dataset.confirmType || 'danger',
      cancelActionTitle: element.dataset.confirmCancelTitle || 'Cancel',
      confirmActionTitle: element.dataset.confirmActionTitle || 'Delete',
      confirmationCode: element.dataset.confirmCode
    }

    window.confirmationModalRails.show(element, confirmationCfg)
    return false
  }

  return confirm(message)
}

i18next.on('initialized', function () {
  const applicationFormStep1El = document.querySelector('.short-term-application-form.step-1')
  if (applicationFormStep1El) {
    const exchangeSelectionEl = applicationFormStep1El.querySelector(':scope [ref="short-term-exchange-selection"]')
    if (exchangeSelectionEl instanceof HTMLElement) {
      const availableExchangesUrl = exchangeSelectionEl.dataset.availableExchangesUrl
      window.shortTermExchangeSelectionControl = tsInitVue(ShortTermExchangeSelection, exchangeSelectionEl, { availableExchangesUrl })
    }
  }

  const applicationFormStep2El = document.querySelector('.application-form.step-2')
  if (applicationFormStep2El) {
    if (!window.nationalContactPoints) {
      window.nationalContactPoints = {}
    }

    const nationalitySelectionEl = applicationFormStep2El.querySelector(':scope select[ref="nationality"]')
    if (nationalitySelectionEl instanceof HTMLSelectElement) {
      const landEl = applicationFormStep2El.querySelector(':scope [ref="land"]')
      const ncpEl = applicationFormStep2El.querySelector('select[ref="ncp"]')
      const idNumberEl = applicationFormStep2El.querySelector(':scope [ref="id_number"]')

      const setupCountryData = function (populateNcps: boolean): void {
        let hideLand = true
        let countryId: number | null = null

        if (nationalitySelectionEl.selectedIndex != -1) {
          const item = nationalitySelectionEl.options[nationalitySelectionEl.selectedIndex]
          if (item) {
            if (item.dataset.requireLand == '1') {
              hideLand = false
            }
            countryId = parseInt(item.dataset.countryId || '')
          }
        }

        if (landEl instanceof HTMLElement) {
          if (hideLand) {
            landEl.classList.add('d-none')
          } else {
            landEl.classList.remove('d-none')
          }
        }


        // hide id nr select
        idNumberEl?.classList?.add('d-none')

        if (ncpEl instanceof HTMLSelectElement && populateNcps) {
          const ncpItems = countryId ? window.nationalContactPoints[countryId.toString()] : []
          if (Array.isArray(ncpItems)) {
            ncpEl.options.length = 0 // clear all options

            const opt = document.createElement("option") // default empty option
            ncpEl.appendChild(opt)

            ncpItems.forEach((val) => {
              const newOpt = document.createElement("option")
              newOpt.value = val[1]
              newOpt.innerHTML = val[0]

              if (val[2]) {
                for (const k in val[2]) {
                  newOpt.setAttribute(k, val[2][k])
                }
              }
              ncpEl.appendChild(newOpt)
            })
          }
        }
      }

      const setupIdNumberField = function(): void {
        let hideIdNumber = true
        if (ncpEl instanceof HTMLSelectElement && ncpEl.selectedIndex != -1) {
          const item = ncpEl.options[ncpEl.selectedIndex]
          if (item) {
            if (item.dataset.requireIdNumber == '1') {
              hideIdNumber = false
            }
          }
        }

        if (idNumberEl instanceof HTMLElement) {
          if (hideIdNumber) {
            idNumberEl.classList.add('d-none')
          } else {
            idNumberEl.classList.remove('d-none')
          }
        }
      }
      // const availableExchangesUrl = exchangeSelectionEl.dataset.availableExchangesUrl
      // window.shortTermExchangeSelectionControl = tsInitVue(ShortTermExchangeSelection, exchangeSelectionEl, { availableExchangesUrl })
      nationalitySelectionEl.addEventListener('change', function() {
        setupCountryData(true)
      })

      ncpEl?.addEventListener('change', function() {
        setupIdNumberField()
      })

      setupCountryData(false)
      setupIdNumberField()
    }

    const applicationFilesEl = applicationFormStep2El.querySelector(':scope application-files')
    if (applicationFilesEl instanceof HTMLElement) {
      const defaultProps: { numberOfFiles?: number; uploadUrl?: string } = {}
      if (applicationFilesEl.dataset.numberOfFiles) {
        const numberOfFiles = parseInt(applicationFilesEl.dataset.numberOfFiles)
        if (!isNaN(numberOfFiles)) {
          defaultProps.numberOfFiles = numberOfFiles
        }
      }
      if (isNonEmptyString(applicationFilesEl.dataset.uploadUrl)) {
        defaultProps.uploadUrl = applicationFilesEl.dataset.uploadUrl
      }
      window.applicationFilesControl = tsInitVue(ApplicationFiles, applicationFilesEl, defaultProps)
    }
  }

  const longTermApplicationFormStep1El = document.querySelector('.long-term-application-form.step-1')
  if (longTermApplicationFormStep1El) {
    const exchangeSelectionEl = longTermApplicationFormStep1El.querySelector(':scope [ref="long-term-exchange-selection"]')
    if (exchangeSelectionEl instanceof HTMLElement) {
      const availableExchangesUrl = exchangeSelectionEl.dataset.availableExchangesUrl
      window.longTermExchangeSelectionControl = tsInitVue(LongTermExchangeSelection, exchangeSelectionEl, { availableExchangesUrl })
    }
  }

  const studyVisitApplicationFormStep1El = document.querySelector('.study-visit-application-form.step-1')
  if (studyVisitApplicationFormStep1El) {
    const exchangeSelectionEl = studyVisitApplicationFormStep1El.querySelector(':scope [ref="study-visit-exchange-selection"]')
    if (exchangeSelectionEl instanceof HTMLElement) {
      const availableExchangesUrl = exchangeSelectionEl.dataset.availableExchangesUrl
      window.studyVisitExchangeSelectionControl = tsInitVue(StudyVisitExchangeSelection, exchangeSelectionEl, { availableExchangesUrl })
    }
  }

  const projectBasedApplicationFormStep1El = document.querySelector('.project-based-application-form.step-1')
  if (projectBasedApplicationFormStep1El) {
    const exchangeSelectionEl = projectBasedApplicationFormStep1El.querySelector('[data-is="ProjectBasedExchangeSelection"]')
    if (exchangeSelectionEl instanceof HTMLElement) {
      window.projectBasedExchangeSelectionControl = tsInitVue(ProjectBasedExchangeSelection, exchangeSelectionEl)
    }
  }

  const uploadFilesElements = document.querySelectorAll(':scope [data-is="upload-files-field"]')
  for (let i = 0; i < uploadFilesElements.length; i++) {
    const upEl = uploadFilesElements[i]
    if (upEl instanceof HTMLElement) {
      initializeMultiUploadField(upEl)
    }
  }

  const availablePlacesTableEL = document.querySelector('[data-is="availablePlacesTable"]')
  if (availablePlacesTableEL instanceof HTMLElement) {
    tsInitVue(AvailablePlacesTable, availablePlacesTableEL, { availablePlaces })
  }
})

document.addEventListener('DOMContentLoaded', function () {
  //
  // initialize date pickers
  //
  const dpEls = document.querySelectorAll('.input-group.date')
  for (let i = 0; i < dpEls.length; i++) {
    const dpEl = dpEls[i]
    if (dpEl instanceof HTMLElement) {
      initializeDatePicker(dpEl)
    }
  }

  $('[data-toggle="tooltip"]').tooltip()
})
