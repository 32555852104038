<template>
  <div class="card choice-card">
    <div class="card-header d-flex align-items-center">
      <span class="required">{{ title }}</span>
      <button
        v-if="hasExchange"
        class="btn btn-sm btn-success ml-auto"
        @click.prevent="$emit('show-select-exchange')"
      >
        {{ $t('application_form.change_choice') }}
      </button>
      <button
        v-if="hasExchange && allowDelete"
        class="btn btn-sm btn-danger ml-2"
        @click.prevent="$emit('delete-exchange')"
      >
        {{ $t('application_form.remove_choice') }}
      </button>
    </div>
    <div class="card-body">
      <div v-if="exchange">
        <div
          v-if="hasErrorMessages"
          class="alert alert-danger"
        >
          <h4>{{ $t('application_form.errors_title') }}</h4>
          <hr>
          <ul>
            <li
              v-for="(message, messageIdx) of exchange.errorMessages"
              :key="messageIdx"
            >
              {{ message }}
            </li>
          </ul>
        </div>
        <div class="row form-row exchange-details">
          <dl class="col">
            <dt>{{ $t('application_form.choice.country') }}</dt>
            <dd>{{ exchange.countryName }}</dd>
          </dl>
          <dl class="col">
            <dt>{{ $t('application_form.choice.public') }}</dt>
            <dd>{{ exchange.functions }}</dd>
          </dl>
          <dl class="col">
            <dt>{{ $t('application_form.choice.available_places') }}</dt>
            <dd>{{ exchange.availablePlaces }}</dd>
          </dl>
          <dl
            v-if="hasSpecialisation"
            class="col"
          >
            <dt>{{ $t('application_form.choice.specialisation') }}</dt>
            <dd>{{ exchange.specialisation }}</dd>
          </dl>
        </div>
        <hr>
        <div class="form-row">
          <div
            v-if="exchange.exchangeTypeItems.length > 0"
            class="form-group col-4"
          >
            <label class="required">{{ $t('application_form.choice.type') }}</label>
            <select
              v-model="exchange.exchangeTypeId"
              class="custom-select custom-select-sm"
            >
              <option
                v-for="exchangeType of exchange.exchangeTypeItems"
                :key="exchangeType.id"
                :value="exchangeType.id"
              >
                {{ exchangeType.name }}
              </option>
            </select>
          </div>
          <div
            v-if="exchange.languageItems.length > 0"
            class="form-group col-4"
          >
            <label class="required">{{ $t('application_form.choice.language') }}</label>
            <select
              v-model="exchange.languageId"
              class="custom-select custom-select-sm"
            >
              <option
                v-for="language of exchange.languageItems"
                :key="language.id"
                :value="language.id"
              >
                {{ language.name }}
              </option>
            </select>
          </div>
          <div
            v-if="exchange.durationItems.length > 0"
            class="form-group col-4"
          >
            <label class="required">{{ $t('application_form.choice.duration') }}</label>
            <select
              v-model="exchange.durationId"
              class="custom-select custom-select-sm"
            >
              <option
                v-for="duration of exchange.durationItems"
                :key="duration.id"
                :value="duration.id"
              >
                {{ duration.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <label>{{ $t('application_form.choice.preferred_period') }}</label>
          <input
            v-model="exchange.preferredPeriod"
            type="text"
            class="form-control form-control-sm"
          >
          <small class="form-text text-muted">{{ $t('application_form.choice.preferred_period_help') }}</small>
        </div>
      </div>
      <div v-else>
        <p class="card-text text-muted text-center"><em>{{ $t('short_term_application.choice_not_set_required') }}</em></p>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-sm btn-info"
            data-toggle="modal"
            data-target="#choicesModal"
            @click.prevent="$emit('show-select-exchange')"
          >
            {{ $t('short_term_application.add_first_choice') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
  import type { ExchangeChoice } from '../types'
  import { isNonEmptyString } from '../../js-utils'

  export default defineComponent({
    name: 'ShortTermExchangeChoiceCard',
    props: {
      title: { type: String, default: '' },
      exchange: { type: Object as () => ExchangeChoice | null, default: null },
      allowDelete: { type: Boolean, default: false },
    },
    computed: {
      hasExchange(): boolean {
        return this.exchange != null
      },

      hasErrorMessages(): boolean {
        return (this.exchange || false) && Array.isArray(this.exchange.errorMessages) && this.exchange.errorMessages.length > 0
      },

      hasSpecialisation(): boolean {
        if (this.exchange) {
          return isNonEmptyString(this.exchange.specialisation)
        }
        return false
      }
    }
  })
</script>
