<template>
  <div>
    <p class="form-text">
      {{ $t('long_term_application.one_selection_notice') }}
    </p>
    <div class="choice-cards">
      <long-term-exchange-choice-card
        :exchange="applicationData.choice"
        :allow-delete="true"
        :title="$t('long_term_application.selected_choice')"
        @show-select-exchange="showSelectExchangeModal"
      />
    </div>

    <teleport
      to="#vue-portal-target"
      append
    >
      <remote-modal
        ref="selectExchangeModal"
        :modal-title="$t('application_form.select_exchange.title')"
        modal-class="modal-tabular"
        modal-dialog-class="modal-xl"
        :loading-message="$t('application_form.select_exchange.loading')"
        :loading-indicator-in-footer="false"
        :hide-content-when-loading="true"
        :allow-keyboard="true"
        @details-loaded="onSelectExchangesLoaded"
      >
        <template
          v-if="selectExchangesListLoaded"
          v-slot:modal-content
        >
          <div
            v-if="selectExchangesList.length > 0"
            class="table-wrapper"
          >
            <div class="table-responsive">
              <table class="table table-hover table-sm">
                <thead>
                  <tr>
                    <th>{{ $t('long_term_application.choice.institution_name') }}</th>
                    <th>{{ $t('long_term_application.choice.available_places') }}</th>
                    <th>{{ $t('long_term_application.choice.duration') }}</th>
                    <th class="tight" />
                    <th class="tight" />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="exchange of selectExchangesList"
                    :key="exchange.id"
                  >
                    <td>{{ exchange.institutionName }}</td>
                    <td>{{ exchange.availablePlaces }}</td>
                    <td>{{ exchange.duration }}</td>
                    <td class="tight">
                      <a
                        v-if="exchangeRequirementsVisible(exchange)"
                        href="#"
                        @click.prevent="showExchangeRequirements(exchange)"
                      >
                        {{ $t('long_term_application.choice.view_requirements') }}
                      </a>
                    </td>
                    <td class="tight">
                      <button
                        type="button"
                        class="btn btn-sm btn-info"
                        @click.prevent="selectExchange(exchange)"
                      >
                        {{ $t('long_term_application.choice.select') }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            v-else
            class="alert alert-warning"
          >
            {{ $t('application_form.select_exchange.no_exchanges_found') }}
          </div>
        </template>
        <template v-slot:modal-footer>
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            data-dismiss="modal"
          >
            {{ $t('modal.button.close') }}
          </button>
        </template>
      </remote-modal>
      <remote-modal
        ref="exchangeRequirementsModal"
        :modal-title="$t('application_form.exchange_requirements_modal.title')"
        :allow-keyboard="true"
        modal-dialog-class="modal-xl exchange-requirements"
        @details-loaded="onExchangeRequirementsLoaded"
      >
        <template v-slot:modal-content>
          <div
            v-if="exchangeRequirementsLoaded"
            class="requirement-list"
          >
            <div
              class="requirement"
              v-html="exchangeRequirements"
            />
          </div>
        </template>
      </remote-modal>
      <modal-alert
        ref="modalAlert"
        :close-button-title="$t('modal.button.close')"
      />
    </teleport>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Jsona from 'jsona'

import RemoteModal from '../../vue-components/remote-modal.vue'
import ModalAlert from '../../vue-components/modal-alert.vue'
import LongTermExchangeChoiceCard from './long-term-exchange-choice-card.vue'

import type { LongTermExchangeChoice } from '../types'
import { isNonEmptyString } from '../../js-utils'

interface LongTermApplication {
  choice: LongTermExchangeChoice | null;
}

export default defineComponent({
  name: 'LongTermExchangeSelection',
  components: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    LongTermExchangeChoiceCard, ModalAlert, RemoteModal
  },
  props: {
    availableExchangesUrl: { default: null, type: String },
  },
  data () {
    const applicationData: LongTermApplication = {
      choice: null,
    }

    return {
      selectExchangesList: [] as LongTermExchangeChoice[],
      selectExchangesListLoaded: false,
      applicationData,
      applicationDataEl: null as HTMLInputElement | null,
      exchangeRequirements: null as string | null,
      functionSelectEl: null as HTMLSelectElement | null,
    }
  },
  computed: {
    exchangeRequirementsLoaded(): boolean {
      return isNonEmptyString(this.exchangeRequirements)
    }
  },
  watch: {
    applicationData: {
      deep: true,

      // We have to move our method to a handler field
      handler(): void {
        this.storeApplicationData()
      }
    }
  },
  mounted () {
    this.applicationDataEl = document.querySelector('input[ref="applicationDataJson"]')
    if (this.applicationDataEl) {
      try {
        const appData = JSON.parse(this.applicationDataEl.value)
        this.applicationData = appData
      } catch {
      }
    }

    const el = document.querySelector('select[ref="function"]')
    if (el instanceof HTMLSelectElement) {
      this.functionSelectEl = el
    }
  },
  methods: {
    showSelectExchangeModal(): void {
      this.selectExchangesListLoaded = false
      if (this.functionSelectEl) {
        const functionId = this.functionSelectEl.value
        if (isNonEmptyString(functionId)) {
          this.$refs.selectExchangeModal.showModal(this.availableExchangesUrl, {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            function_id: functionId
          })
          return
        }
      }

      this.$refs.modalAlert.show(
        this.$t('application_form.select_exchange.error.no_function_selected.title'),
        this.$t('application_form.select_exchange.error.no_function_selected.message'),
        'danger'
      )
    },

    onSelectExchangesLoaded(data: any): void {
      // this.selectExchangesList.push({country: 'xxx'})
      this.selectExchangesListLoaded = true
      const loadedData = new Jsona().deserialize(data)
      if (Array.isArray(loadedData)) {
        this.selectExchangesList = loadedData as LongTermExchangeChoice[]
      }
    },

    selectExchange(exchange: LongTermExchangeChoice | null): void {
      this.$refs.selectExchangeModal.hideModal()

      if (exchange) {
        this.applicationData.choice = this.prepareExchange(exchange, this.applicationData.choice)
      }
    },

    prepareExchange(exchange: LongTermExchangeChoice, previousExchange: LongTermExchangeChoice | null): LongTermExchangeChoice {
      // copy exchange
      exchange = JSON.parse(JSON.stringify(exchange))

      if (previousExchange) {
        if (previousExchange.durationId) {
          const durationExists = exchange.durationItems.some(x => x.id == previousExchange.durationId)
          if (durationExists) {
            exchange.durationId = previousExchange.durationId
          }
        }
        exchange.specialInterest = previousExchange.specialInterest
      }
      return exchange
    },

    storeApplicationData(): void {
      if (this.applicationDataEl) {
        this.applicationDataEl.value = JSON.stringify(this.applicationData)
      }
    },

    exchangeRequirementsVisible(exchange: LongTermExchangeChoice): boolean {
      if (exchange.links) {
        return isNonEmptyString(exchange.links.requirements)
      }
      return false
    },

    showExchangeRequirements(exchange: LongTermExchangeChoice): void {
      this.exchangeRequirements = null
      if (exchange.links && isNonEmptyString(exchange.links.requirements)) {
        this.$refs.exchangeRequirementsModal.showModal(exchange.links.requirements)
      }
    },

    onExchangeRequirementsLoaded(data: any): void {
      if (isNonEmptyString(data)) {
        this.exchangeRequirements = data
      }
    },
  }
})
</script>
