<template>
  <div>
    <div class="choice-cards">
      <study-visit-exchange-choice-card
        :exchange="applicationData.choice"
        :allow-delete="true"
        :title="$t('study_visit_application.selected_choice')"
        @show-select-exchange="showSelectExchangeModal"
      />
    </div>

    <teleport
      to="#vue-portal-target"
      append
    >
      <remote-modal
        ref="selectExchangeModal"
        :modal-title="$t('application_form.select_exchange.title')"
        modal-class="modal-tabular"
        modal-dialog-class="modal-xl"
        :loading-message="$t('application_form.select_exchange.loading')"
        :loading-indicator-in-footer="false"
        :hide-content-when-loading="true"
        :allow-keyboard="true"
        @details-loaded="onSelectExchangesLoaded"
      >
        <template
          v-if="selectExchangesListLoaded"
          v-slot:modal-content
        >
          <div
            v-if="selectExchangesList.length > 0"
            class="table-wrapper"
          >
            <div class="table-responsive">
              <table class="table table-hover table-sm study-visit-periods">
                <thead>
                  <tr>
                    <th>{{ $t('study_visit_application.choice.institution_name_period') }}</th>
                    <th>{{ $t('study_visit_application.choice.language') }}</th>
                    <th>{{ $t('study_visit_application.choice.available_places') }}</th>
                    <th class="tight" />
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="exchange of selectExchangesList"
                    :key="`sv-${exchange.id}`"
                  >
                    <tr class="table-secondary">
                      <td colspan="3"><strong>{{ exchange.institutionName }}</strong></td>
                      <td class="tight-right">
                        <a
                          v-if="exchangeRequirementsVisible(exchange)"
                          href="#"
                          @click.prevent="showExchangeRequirements(exchange)"
                        >
                          View requirements
                        </a>
                      </td>
                    </tr>
                    <tr
                      v-for="period of exchange.periods"
                      :key="`svp-${period.id}`"
                      class="period"
                    >
                      <td class="dates">{{ period.dates }}</td>
                      <td>{{ period.language }}</td>
                      <td class="text-center">{{ period.availablePlaces }}</td>
                      <td class="tight-right">
                        <button
                          type="button"
                          class="btn btn-sm btn-info"
                          @click.prevent="selectExchange(period)"
                        >
                          {{ $t('long_term_application.choice.select') }}
                        </button>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div
            v-else
            class="alert alert-warning"
          >
            {{ $t('application_form.select_exchange.no_exchanges_found') }}
          </div>
        </template>
        <template v-slot:modal-footer>
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            data-dismiss="modal"
          >
            {{ $t('modal.button.close') }}
          </button>
        </template>
      </remote-modal>
      <remote-modal
        ref="exchangeRequirementsModal"
        :modal-title="$t('application_form.exchange_requirements_modal.title')"
        :allow-keyboard="true"
        modal-dialog-class="modal-xl exchange-requirements"
        @details-loaded="onExchangeRequirementsLoaded"
      >
        <template v-slot:modal-content>
          <div
            v-if="exchangeRequirementsLoaded"
            class="requirement-list"
          >
            <div
              class="requirement"
              v-html="exchangeRequirements"
            />
          </div>
        </template>
      </remote-modal>
    </teleport>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import Jsona from 'jsona'

import RemoteModal from '../../vue-components/remote-modal.vue'
import StudyVisitExchangeChoiceCard from './study-visit-exchange-choice-card.vue'

import type { StudyVisitPlace, StudyVisitPlacePeriodChoice } from '../types'
import { isNonEmptyString } from '../../js-utils'

interface StudyVisitApplication {
  choice: StudyVisitPlacePeriodChoice | null;
}

export default defineComponent({
  name: 'StudyVisitExchangeSelection',
  components: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    RemoteModal, StudyVisitExchangeChoiceCard
  },
  props: {
    availableExchangesUrl: { default: null, type: String },
  },
  data () {
    const applicationData: StudyVisitApplication = {
      choice: null,
    }

    return {
      selectExchangesList: [] as StudyVisitPlace[],
      selectExchangesListLoaded: false,
      applicationData,
      applicationDataEl: null as HTMLInputElement | null,
      exchangeRequirements: null as string | null,
    }
  },
  computed: {
    exchangeRequirementsLoaded(): boolean {
      return isNonEmptyString(this.exchangeRequirements)
    }
  },
  watch: {
    applicationData: {
      deep: true,

      // We have to move our method to a handler field
      handler(): void {
        this.storeApplicationData()
      }
    }
  },
  mounted () {
    this.applicationDataEl = document.querySelector('input[ref="applicationDataJson"]')
    if (this.applicationDataEl) {
      try {
        const appData = JSON.parse(this.applicationDataEl.value)
        this.applicationData = appData
      } catch {
      }
    }
  },
  methods: {
    showSelectExchangeModal(): void {
      this.selectExchangesListLoaded = false
      this.$refs.selectExchangeModal.showModal(this.availableExchangesUrl)
    },

    onSelectExchangesLoaded(data: any): void {
      // this.selectExchangesList.push({country: 'xxx'})
      this.selectExchangesListLoaded = true
      const loadedData = new Jsona().deserialize(data)
      if (Array.isArray(loadedData)) {
        this.selectExchangesList = loadedData as StudyVisitPlace[]
      }
    },

    selectExchange(exchange: StudyVisitPlacePeriodChoice | null): void {
      this.$refs.selectExchangeModal.hideModal()

      if (exchange) {
        this.applicationData.choice = this.prepareExchange(exchange, this.applicationData.choice)
      }
    },

    prepareExchange(exchange: StudyVisitPlacePeriodChoice, previousExchange: StudyVisitPlacePeriodChoice | null): StudyVisitPlacePeriodChoice {
      // copy exchange
      exchange = JSON.parse(JSON.stringify(exchange))

      if (previousExchange) {
        exchange.specialInterest = previousExchange.specialInterest
      }
      return exchange
    },

    storeApplicationData(): void {
      if (this.applicationDataEl) {
        this.applicationDataEl.value = JSON.stringify(this.applicationData)
      }
    },

    exchangeRequirementsVisible(exchange: StudyVisitPlace): boolean {
      if (exchange.links) {
        return isNonEmptyString(exchange.links.requirements)
      }
      return false
    },

    showExchangeRequirements(exchange: StudyVisitPlace): void {
      this.exchangeRequirements = null
      if (exchange.links && isNonEmptyString(exchange.links.requirements)) {
        this.$refs.exchangeRequirementsModal.showModal(exchange.links.requirements)
      }
    },

    onExchangeRequirementsLoaded(data: any): void {
      if (isNonEmptyString(data)) {
        this.exchangeRequirements = data
      }
    },
  }
})
</script>
