<script setup lang="ts">
import {computed, onMounted, ref, watch} from 'vue'
import ReimbursementFilesCertificateControl from "@/js/vue-components/ospe/reimbursement-files-certificate-control.vue"
import {isNonEmptyString} from "@/js/js-utils";
import {type ReimbursementFile, ReimbursementFileType} from "@/js/vue-components/ospe/reimbursement-file";
import ReimbursementFilesAgendaControl from "@/js/vue-components/ospe/reimbursement-files-agenda-control.vue";


const props = defineProps({
  uploadUrl: {default: '', type: String},
  valueFieldRef: {default: '', type: String},
  withCertificate: {default: false, type: Boolean},
  withAgenda: {default: false, type: Boolean},
  withFinanceException: { default: false, type: Boolean },
  agendaFileRequired: {default: false, type: Boolean},
  certificateFilesTitle: {default: null, type: String},
  financeExceptionFilesTitle: {default: null, type: String},
})

const reimbursementFiles = ref<ReimbursementFile[]>([])
const valueField = ref<HTMLInputElement | null>(null)

const certificateFiles = computed(() => {
  return reimbursementFiles.value.filter(file => file.typeId == ReimbursementFileType.completionCertificate)
})

const agendaFile = computed(() => {
  return reimbursementFiles.value.findLast(file => file.typeId == ReimbursementFileType.agendaOrProgramme)
})

const financeExceptionFiles = computed(() => {
  return reimbursementFiles.value.filter(file => file.typeId == ReimbursementFileType.financeExceptionRequest)
})

watch(
  () => reimbursementFiles,
  () => {
    if (valueField.value) {
      valueField.value.value = JSON.stringify(reimbursementFiles.value)
    }
  },
  {deep: true}
)

onMounted(() => {
  if (isNonEmptyString(props.valueFieldRef)) {
    const field = document.querySelector(`input[ref="${props.valueFieldRef}"]`)
    if (field instanceof HTMLInputElement) {
      valueField.value = field
      try {
        const filesData = JSON.parse(field.value)
        if (Array.isArray(filesData)) {
          reimbursementFiles.value = filesData
        }
      } catch {
      }
    }
  }
})

const onFileUploaded = (file: ReimbursementFile) => {
  reimbursementFiles.value.push(file)

  if (file.typeId == ReimbursementFileType.agendaOrProgramme) {
    for(const f of reimbursementFiles.value) {
      if (f.typeId == ReimbursementFileType.agendaOrProgramme && f.id != file.id) {
        deleteFile(f)
      }
    }
  }
}

const deleteFile = (file: ReimbursementFile) => {
  file.deleted = true
}

const restoreFile = (file: ReimbursementFile) => {
  file.deleted = false
}

</script>

<template>
  <reimbursement-files-certificate-control
    v-if="withCertificate"
    :file-type="ReimbursementFileType.completionCertificate"
    :files="certificateFiles"
    :upload-url="uploadUrl"
    :title="certificateFilesTitle"
    data-test-id="certificate-card"
    @file-uploaded="onFileUploaded"
    @delete-file="deleteFile"
    @restore-file="restoreFile"
  />
  <reimbursement-files-agenda-control
    v-if="withAgenda"
    :file="agendaFile"
    :required="agendaFileRequired"
    :upload-url="uploadUrl"
    data-test-id="agenda-card"
    @file-uploaded="onFileUploaded"
    @delete-file="deleteFile"
    @restore-file="restoreFile"
  />
  <reimbursement-files-certificate-control
    v-if="withFinanceException"
    :file-type="ReimbursementFileType.financeExceptionRequest"
    :files="financeExceptionFiles"
    :upload-url="uploadUrl"
    :title="financeExceptionFilesTitle"
    data-test-id="finance-exception-card"
    @file-uploaded="onFileUploaded"
    @delete-file="deleteFile"
    @restore-file="restoreFile"
  />
</template>
