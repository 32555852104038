<template>
  <div>
    <hr class="short">

    <div class="form-row">
      <div class="form-group col-sm-6">
        <label
          for="activity-id"
          class="required"
        >
          {{ $t('project_based_application.activity') }}
        </label>
        <select
          id="activity-id"
          v-model="selectedActivityId"
          class="custom-select"
        >
          <option :value="null" />
          <option
            v-for="activity of activities"
            :key="activity.id"
            :value="activity.id"
          >
            {{ activity.name }}
          </option>
        </select>
      </div>

      <div class="form-group col-sm-6">
        <label
          for="function-id"
          class="required"
        >
          {{ $t('project_based_application.function') }}
        </label>
        <select
          id="function-id"
          v-model="applicationData.functionId"
          class="custom-select"
          :disabled="!selectFunctionDisabled"
        >
          <option :value="null" />
          <option
            v-for="aFunction of functions"
            :key="aFunction.id"
            :value="aFunction.id"
          >
            {{ aFunction.name }}
          </option>
        </select>
      </div>
    </div>

    <template v-if="selectedActivity">
      <hr class="short">
      <h4>{{ $t('project_based_application.instructions') }}</h4>
      <div
        v-if="hasInstructions"
        v-html="selectedActivity.instructionsHtml"
      />

      <div
        v-for="file of files"
        :key="file.url"
      >
        <a
          :href="file.url"
          target="_blank"
        >
          {{ file.fileName }}
        </a>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'

import type { OspeFunction, OspeProjectBasedActivity, OspeProjectBasedActivityTemplateFile, ProjectBasedApplication } from '../types'
import { isNonEmptyString } from '@/js/js-utils';

export default defineComponent({
  name: 'ProjectBasedExchangeSelection',
  data () {
    const applicationData: ProjectBasedApplication = {
      activityId: null,
      functionId: null
    }

    return {
      activities: [] as OspeProjectBasedActivity[],
      selectedActivity: null as OspeProjectBasedActivity | null,
      applicationData,
      applicationDataEl: null as HTMLInputElement | null,
    }
  },
  computed: {
    selectedActivityId: {
      get(): number | null {
        return this.selectedActivity && this.selectedActivity.id
      },
      set(newId: number | null): void {
        const activity = this.activities.find(item => item.id == newId)
        if (activity) {
          this.applicationData.activityId = activity.id
          this.selectedActivity = activity
        } else {
          this.applicationData.activityId = null
          this.selectedActivity = null
        }
      }
    },

    hasFunction(): boolean {
      return this.applicationData.functionId !== null
    },

    selectFunctionDisabled (): boolean {
      return this.selectedActivity != null
    },

    functions (): OspeFunction[] {
      if (this.selectedActivity && Array.isArray(this.selectedActivity.functions)) {
        return this.selectedActivity.functions
      }
      return []
    },

    hasInstructions(): boolean {
      if (this.selectedActivity) {
        return isNonEmptyString(this.selectedActivity.instructionsHtml)
      }

      return false
    },

    files(): OspeProjectBasedActivityTemplateFile[] {
      if (this.selectedActivity && Array.isArray(this.selectedActivity.templateFiles)) {
        return this.selectedActivity.templateFiles
      }

      return []
    }
  },
  watch: {
    applicationData: {
      deep: true,

      // We have to move our method to a handler field
      handler(): void {
        this.storeApplicationData()
      }
    },

    'applicationData.activityId': function(): void {
      let newFunctionId = null
      const aFunction = this.functions.find(func => func.id == this.applicationData.functionId)
      if (aFunction) {
        newFunctionId = aFunction.id
      }
      this.applicationData.functionId = newFunctionId
    },
  },
  mounted () {
    const exchangesDataEl = document.querySelector('script[ref="exchangeSelectionData"]')
    if (exchangesDataEl instanceof HTMLScriptElement && exchangesDataEl.type == 'application/json') {
      try {
        const exchangeData = JSON.parse(exchangesDataEl.innerText)
        if (Array.isArray(exchangeData.activities)) {
          this.activities = exchangeData.activities
        }
      }
      catch {
      }
    }
    this.applicationDataEl = document.querySelector('input[ref="applicationDataJson"]')
    if (this.applicationDataEl) {
      try {
        const appData = JSON.parse(this.applicationDataEl.value)
        this.applicationData = appData
        this.selectedActivityId = this.applicationData.activityId
      } catch {
      }
    }
  },
  methods: {
    storeApplicationData(): void {
      if (this.applicationDataEl) {
        this.applicationDataEl.value = JSON.stringify(this.applicationData)
      }
    },
  }
})
</script>
