import '../js/polyfill'

import 'bootstrap'
import Rails from '@rails/ujs'

import '../js/polyfill.js'
import '../js/ospe-public/i18n'
import '../js/ospe-public/main'
import "../js/ospe-public/registration"
import "../js/ospe-public/reimbursement"
import "../js/ospe-public/report-submission"

import '../images/ospe/pub/logo-en.png'
import '../images/ospe/pub/logo-fr.png'
import '../images/ospe/pub/cover.png'
import {initSentry} from "@/js/sentry";

initSentry()
if (!window.Rails) Rails.start() // https://github.com/ElMassimo/vite_ruby/issues/246#issuecomment-1235743299
